import React from "react";
import classes from "./AboutUs.module.scss";
import GlobeImage from "../../assets/AboutUsImages/Globe_image.png"

function OurPresence() {
  return (
    <div className={classes.presenceContainer}>
      <span className={classes.ourPresesnceTitle}>OUR PRESENCE</span>
      <span className={classes.subtitles}>
      We continue to build a global footprint with a presence in 15+ countries across the world.
      </span>
      <div className={classes.countriesCircleComponent}>
      <div>
        <span className={classes.countryTitle}>Europe</span>
        <div className={classes.cityComponent}>
          <span className={classes.cityTitle}>Greece,</span>
          <span className={classes.cityTitle}>France,</span>
        </div>
        <span className={classes.countryTitle}>Middle East</span>
        <div className={classes.cityComponent}>
          <span className={classes.cityTitle}>Lebanon,</span>
          <span className={classes.cityTitle}>U.A.E,</span>
          <span className={classes.cityTitle}>Jordan,</span>
        </div>
        <span className={classes.countryTitle}>Africa</span>
          <span className={classes.cityTitle}>Ghana, Nigeria,</span>
          <span className={classes.cityTitle}>Tanzania, Senegal,</span>
          <span className={classes.cityTitle}> Ivory Coast, DRC,</span>
          <span className={classes.cityTitle}> Zambia, Kenya,</span>
          <span className={classes.cityTitle}>Cameroon, Uganda,</span>
          <span className={classes.cityTitle}> South Africa </span>

      </div>
        <div className={classes.circlePresence}>
          <img src={GlobeImage} alt="Globel" className={classes.presenceImage}/>
        </div>
      </div>
    </div>
  );
}

export default OurPresence;
