import React from "react";
import classes from "./MoobiPay.module.scss";
import Banksicon from "../../assets/MoobiPayImages/Banks_icon.svg";
import operatorsicon from "../../assets/MoobiPayImages/operators_icon.svg";
import ExternalWalleticons from "../../assets/MoobiPayImages/ExternalWallet_icons.svg";
import ExternalProvidericon from "../../assets/MoobiPayImages/ExternalProvider_icon.svg";

function PaymentMethods() {
  let PaymentMethods = [
    {
      Title: "Operators",
      Icon: operatorsicon,
    },
    {
      Title: "External Providers",
      Icon: ExternalProvidericon,
    },
    {
      Title: "Banks ",
      Icon: Banksicon,
    },
    {
      Title: "External Wallets",
      Icon: ExternalWalleticons,
    },
  ];

  let PaymentMethodsMobile1 = [
    {
      Title: "Operators",
      Icon: operatorsicon,
    },
    {
      Title: "External Providers",
      Icon: ExternalProvidericon,
    },
  ];

  let PaymentMethodsMobile2 = [
    {
      Title: "Banks ",
      Icon: Banksicon,
    },
    {
      Title: "External Wallets",
      Icon: ExternalWalleticons,
    },
  ];

  return (
    <div className="container">
    <div className={classes.paymentMethodComponent}>
      <div className={classes.titleContainer}>
        <span className={classes.mainTitle}>
          Payment <span className={classes.secondMainTitle}>Methods</span>
        </span>
      </div>
      <div className={classes.coreFeaturesDetails}>
        <div className={classes.coreFeaturesWrapper}>
          {PaymentMethods &&
            PaymentMethods.map((item, index) => {
              return (
                <div key={index} className={classes.securityInfoInside}>
                  <div className={classes.coreFeaturesCircle}>
                    <img
                      src={item.Icon}
                      alt="features Icons"
                      className={classes.coreFeaturesIcons}
                    />
                  </div>
                  <span className={classes.featurestTitleUnderIcon}>
                    {item.Title}
                  </span>
                </div>
              );
            })}
        </div>

        <div className={classes.coreFeaturesWrapperMobile1}>
          {PaymentMethodsMobile1 &&
            PaymentMethodsMobile1.map((item, index) => {
              return (
                <div key={index} className={classes.securityInfoInside}>
                  <div className={classes.coreFeaturesCircle}>
                    <img
                      src={item.Icon}
                      alt="features Icons"
                      className={classes.coreFeaturesIcons}
                    />
                  </div>
                  <span className={classes.featurestTitleUnderIcon}>
                    {item.Title}
                  </span>
                </div>
              );
            })}
        </div>

        <div className={classes.coreFeaturesWrapperMobile1}>
          {PaymentMethodsMobile2 &&
            PaymentMethodsMobile2.map((item, index) => {
              return (
                <div key={index} className={classes.securityInfoInside}>
                  <div className={classes.coreFeaturesCircle}>
                    <img
                      src={item.Icon}
                      alt="features Icons"
                      className={classes.coreFeaturesIcons}
                    />
                  </div>
                  <span className={classes.featurestTitleUnderIcon}>
                    {item.Title}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
    </div>
  );
}

export default PaymentMethods;
