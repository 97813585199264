import React from "react";
import classes from "./MainBanner.module.scss";

function MainBanner({
  Title,
  ColoredTitle,
  ImageBanner,
  Secondtitle,
  SecondcoloredTitle,
  subTitle,
  topChange,
}) {
  return (
    <div className={classes.MainBannerStyle}>
      <img src={ImageBanner} className={classes.bannerStyle} alt="Banner" />
      <div>
        {Title || ColoredTitle ? (
          <span className={`${classes.titleBanner} ${classes[topChange]}`}>
            {Title}
            <br />
            <span className={classes.titleSecondPart}>{ColoredTitle}</span>
          </span>
        ) : (
          <div className={classes.secondInfo}>
            <span className={`${classes.titleBanner} ${classes[topChange]}`}>
              {Secondtitle}
              <span className={classes.titleSecondPart}>
                {SecondcoloredTitle}
              </span>
            </span>
            <span className={classes.subTitles}>{subTitle}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default MainBanner;
