import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from "./Slider.module.scss";
import RFI from "../../assets/CompanyLogos/RFI.svg";
import Atari from "../../assets/CompanyLogos/Atari.svg";
import NLAlogo from "../../assets/CompanyLogos/NLA_logo.svg";
import Risklogo from "../../assets/CompanyLogos/Risk_logo.svg";
import Xbetlogo from "../../assets/CompanyLogos/1Xbet_logo.svg";
import Atlaslogo from "../../assets/CompanyLogos/Atlas_logo.svg";
import Sanadlogo from "../../assets/CompanyLogos/Sanad_logo.svg";
import Moneylogo from "../../assets/CompanyLogos/Money_logo.svg";
import Lonacilogo from "../../assets/CompanyLogos/Lonaci_logo.png";
import Lonaselogo from "../../assets/CompanyLogos/Lonase_logo.svg";
import BetMojologo from "../../assets/CompanyLogos/BetMojo_logo.svg";
import GolCashlogo from "../../assets/CompanyLogos/GolCash_logo.svg";
import GR8Techlogo from "../../assets/CompanyLogos/GR8Tech_logo.svg";
import Digitainlogo from "../../assets/CompanyLogos/Digitain_logo.svg";
import EuroNewslogo from "../../assets/CompanyLogos/EuroNews_logo.svg";
import France24logo from "../../assets/CompanyLogos/France24_logo.svg";
import MojaGrouplogo from "../../assets/CompanyLogos/MojaGroup_logo.svg";
import AfricaNewslogo from "../../assets/CompanyLogos/AfricaNews_logo.svg";
import GrupoPefacologo from "../../assets/CompanyLogos/Grupo_PEFACO.png";
import UFAAssuranceslogo from "../../assets/CompanyLogos/UFAAssurances_logo.svg";
import EvolutionGaminglogo from "../../assets/CompanyLogos/EvolutionGaming_logo.svg";
import OlympiquedeMarseillelogo from "../../assets/CompanyLogos/OlympiquedeMarseille_logo.svg";



const settings = {
  rows: 1,
  dots: false,
  arrows: true,
  infinite: true,
  speed: 300,
  slidesToShow: 5,
  slidesToScroll: 5,
  mobileFirst: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        rows: 1,
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        rows: 1,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },
  ],
};

function SliderPartners() {

  return (
    <div className={`container ${classes.sliderContainer} black-arrows`}>
      <Slider {...settings} className={classes.sliderCont}>
        <div>
          <div className={classes.logoContainer}>
            <img src={Atlaslogo} alt="Atlaslogo" className={classes.logoIcon} />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={BetMojologo}
              alt="BetMojologo"
              className={classes.logoIcon}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img src={Atari} alt="Atari" className={classes.logoIcon} />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={AfricaNewslogo}
              alt="AfricaNewslogo"
              className={classes.logoIcon}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={Xbetlogo}
              alt="ufaLogo"
              className={classes.logoXbetlogo}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={Digitainlogo}
              alt="Digitainlogo"
              className={classes.logoDigitainlogo}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={EuroNewslogo}
              alt="EuroNewslogo"
              className={classes.logoIcon}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={EvolutionGaminglogo}
              alt="EvolutionGaminglogo"
              className={classes.evolutionGamingLogoIcon}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={France24logo}
              alt="France24logo"
              className={classes.logoIcon}
            />
          </div>
        </div>

        <div>
          <div className={classes.logoContainer}>
            <img
              src={GolCashlogo}
              alt="GolCashlogo"
              className={classes.logoIcon}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={GR8Techlogo}
              alt="GR8Techlogo"
              className={classes.GR8TechLogoIcon}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={GrupoPefacologo}
              alt="GrupoPefacologo"
              className={classes.grupoPefacoLogoIcon}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainerLonaci}>
            <img
              src={Lonacilogo}
              alt="Lonacilogo"
              className={classes.logoLonaci}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainerLonace}>
            <img
              src={Lonaselogo}
              alt="Lonaselogo"
              className={classes.lonaseLogoIcon}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={MojaGrouplogo}
              alt="MojaGrouplogo"
              className={classes.logoIcon}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={Moneylogo}
              alt="Moneylogo"
              className={classes.logoMoney}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img src={NLAlogo} alt="NLAlogo" className={classes.logoIcon} />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={OlympiquedeMarseillelogo}
              alt="OlympiquedeMarseillelogo"
              className={classes.logoIcon}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img src={RFI} alt="RFIlogo" className={classes.logoIcon} />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img src={Risklogo} alt="Risklogo" className={classes.RisklogoIcon} />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img src={Sanadlogo} alt="Sanadlogo" className={classes.SanadlogoIcon} />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={UFAAssuranceslogo}
              alt="UFAAssuranceslogo"
              className={classes.logoIcon}
            />
          </div>
        </div>
      </Slider>
    </div>
  );
}

export default SliderPartners;
