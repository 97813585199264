import React from "react";
import classes from "./MoobiPay.module.scss";
import Reportingicon from "../../assets/MoobiPayImages/Reporting_icon.svg";
import transSettleicon from "../../assets/MoobiPayImages/transSettle_icon.svg";
import authResponseicon from "../../assets/MoobiPayImages/authResponse_icon.svg";
import PaymentGateWayicon from "../../assets/MoobiPayImages/PaymentGateWay_icon.svg";
import FraudDetectionicon from "../../assets/MoobiPayImages/FraudDetection_icon.svg";
import transactionAuthicon from "../../assets/MoobiPayImages/transactionAuth_icon.svg";
import transCompletionicon from "../../assets/MoobiPayImages/transCompletion_icon.svg";
import transactionInitiationicon from "../../assets/MoobiPayImages/transactionInitiation_icon.svg";

function PaymentProcess() {
  let PaymentProcess1 = [
    {
      Title: "Fraud Detection and Verification",
      Paragraph:
        "The customer begins the payment process by providing payment details (e.g., credit card information, bank account details).",
      Icon: transactionInitiationicon,
      number: "1",
    },
    {
      Title: "Payment Gateway",
      Paragraph:
        "A secure gateway that processes the payment information and forwards it to the appropriate payment processor.",
      Icon: PaymentGateWayicon,
      number: "2",
    },
    {
      Title: "Transaction Authorization",
      Paragraph:
        "The payment processor contacts the issuing bank or financial institution to check for sufficient funds and verify the transaction's legitimacy.",
      Icon: transactionAuthicon,
      number: "3",
    },
  ];

  let PaymentProcess2 = [
    {
      Title: "Fraud Detection and Verification",
      Paragraph:
        "Additional checks to detect any fraudulent activity or validate the customer's identity.",
      Icon: FraudDetectionicon,
      number: "4",
    },
    {
      Title: "Authorization Response",
      Paragraph:
        "The issuing bank or financial institution sends an approval or denial message back to the payment gateway.",
      Icon: authResponseicon,
      number: "5",
    },
  ];

  let PaymentProcess3 = [
    {
      Title: "Transaction Completion",
      Paragraph:
        "The merchant receives the authorization response and completes the transaction if approved.",
      Icon: transCompletionicon,
      number: "6",
    },
    {
      Title: "Transaction Settlement",
      Paragraph:
        "The funds are transferred from the customer's account to the merchant’s account, finalizing the transaction.",
      Icon: transSettleicon,
      number: "7",
    },
    {
      Title: "Reconciliation and Reporting",
      Paragraph:
        "Both the merchant and the financial institutions update their records to reflect the completed transaction and ensure accuracy.",
      Icon: Reportingicon,
      number: "8",
    },
  ];

  return (
    <div className={classes.allComponent}>
      <div className={classes.titleContainer}>
        <span className={classes.mainTitle}>
          Payment<span className={classes.secondMainTitle}>Process</span>
        </span>
      </div>
      <div className={classes.paymenProcessCont}>
        <div className={classes.paymentProcessWrapper}>
          {PaymentProcess1 &&
            PaymentProcess1.map((item, index) => {
              return (
                <div key={index} className={classes.paymentProcessinfoInside}>
                  <div className={classes.paymentProcessCircle}>
                    <div className={classes.whiteCircle}>
                      <img
                        src={item.Icon}
                        alt="paymentProcess Icons"
                        className={classes.paymentProcessIcons}
                      />
                      <span className={classes.paymentProcesstTitleUnderIcon}>
                        {item.Title}
                      </span>
                      <div className={classes.halfCircle}>
                        <span className={classes.numberInside}>
                          {item.number}
                        </span>
                      </div>
                    </div>
                  </div>
                  <p className={classes.paragraphStyle}>{item.Paragraph}</p>
                </div>
              );
            })}
        </div>

        <div className={classes.paymentProcessWrapper1}>
          {PaymentProcess2 &&
            PaymentProcess2.map((item, index) => {
              return (
                <div key={index} className={classes.paymentProcessinfoInsideMiddle}>
                  <div className={classes.paymentProcessCircle}>
                    <div className={classes.whiteCircle}>
                      <img
                        src={item.Icon}
                        alt="paymentProcess Icons"
                        className={classes.paymentProcessIcons}
                      />
                      <span className={classes.paymentProcesstTitleUnderIcon}>
                        {item.Title}
                      </span>
                      <div className={classes.halfCircle}>
                        <span className={classes.numberInside}>
                          {item.number}
                        </span>
                      </div>
                    </div>
                  </div>
                  <p className={classes.paragraphStyle}>{item.Paragraph}</p>
                </div>
              );
            })}
        </div>

        <div className={classes.paymentProcessWrapper}>
          {PaymentProcess3 &&
            PaymentProcess3.map((item, index) => {
              return (
                <div key={index} className={classes.paymentProcessinfoInside}>
                  <div className={classes.paymentProcessCircle}>
                    <div className={classes.whiteCircle}>
                      <img
                        src={item.Icon}
                        alt="paymentProcess Icons"
                        className={classes.paymentProcessIcons}
                      />
                      <span className={classes.paymentProcesstTitleUnderIcon}>
                        {item.Title}
                      </span>
                      <div className={classes.halfCircle}>
                        <span className={classes.numberInside}>
                          {item.number}
                        </span>
                      </div>
                    </div>
                  </div>
                  <p className={classes.paragraphStyle}>{item.Paragraph}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default PaymentProcess;
