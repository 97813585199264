import React from "react";
import classes from "./MoobiDev.module.scss";
import DataDecissionicon from "../../assets/MoobiDevImages/DataDecission_icon.svg";
import MarketResearchicon from "../../assets/MoobiDevImages/MarketResearch_icon.svg";
import CrossFunctionalicon from "../../assets/MoobiDevImages/CrossFunctional_icon.svg";
import CustomerCentricicon from "../../assets/MoobiDevImages/CustomerCentric_icon.svg";
import AgileDevelopmenticon from "../../assets/MoobiDevImages/AgileDevelopment_icon.svg";
import StrategicMappingicon from "../../assets/MoobiDevImages/StrategicMapping_icon.svg";
import InnovationApproveicon from "../../assets/MoobiDevImages/InnovationApprove_icon.svg";

function ProductManager() {
  let ProductManager1 = [
    {
      Title: "Customer-Centric",
      SecondTilte: "Approach",
      Icon: CustomerCentricicon,
    },
    {
      Title: "Market Research",
      SecondTilte: "and Insights",
      Icon: MarketResearchicon,
    },
    {
      Title: "Agile Development ",
      Icon: AgileDevelopmenticon,
    },
    {
      Title: "Cross-Functional ",
      SecondTilte: "Collaboration",
      Icon: CrossFunctionalicon,
    },
    {
      Title: "Data-Driven Decision",
      SecondTilte: "making",
      Icon: DataDecissionicon,
    },
    {
      Title: "Innovation and Continuous",
      SecondTilte: "Improvement",
      Icon: InnovationApproveicon,
    },
    {
      Title: "Strategic Road-Mapping ",
      Icon: StrategicMappingicon,
    },
  ];

  return (
    <div className="container">
    <div className={classes.productManagerContainer}>
      <div className={classes.productManagerWrapper}>
        {ProductManager1 &&
          ProductManager1.map((item, index) => {
            return (
              <div key={index} className={classes.productManagerCircleTitle}>
                <div className={classes.productManagerCircles}>
                  <img
                    src={item.Icon}
                    alt="Software Icons"
                    className={classes.productManagerIcons}
                  />
                </div>
                <div className={classes.titleCont}>
                  <span className={classes.productManagertitleUnderIcon}>
                    {item.Title} <br />
                    {item.SecondTilte ? <span>{item.SecondTilte}</span> : ""}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
    </div>
  );
}

export default ProductManager;
