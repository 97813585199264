import React from "react";
import classes from "./MoobiPlus.module.scss";
import ReportingIcon from "../../assets/MoobiPlusImages/Reporting_Icon.svg";
import Marketingicon from "../../assets/MoobiPlusImages/Marketing_icon.svg";
import UserAccounticon from "../../assets/MoobiPlusImages/UserAccount_icon.svg";
import Performenceicon from "../../assets/MoobiPlusImages/Performence_icon.svg";
import MultiPlatformicon from "../../assets/MoobiPlusImages/MultiPlatform_icon.svg";
import GameManagmenticon from "../../assets/MoobiPlusImages/GameManagment_icon.svg";
import CustomerSupporticon from "../../assets/MoobiPlusImages/CustomerSupport_icon.svg";
import ContentManagmenticon from "../../assets/MoobiPlusImages/ContentManagment_icon.svg";
import Adminpanelsettingsicon from "../../assets/MoobiPlusImages/Adminpanelsettings_icon.svg";

function CompanyFeatures() {
  let CompanyFeaturesObject1 = [
    {
      Title: "Game  ",
      SecondTilte: "Management",
      Icon: GameManagmenticon,
    },
    {
      Title: "User Account ",
      SecondTilte: "Management",
      Icon: UserAccounticon,
    },
    {
      Title: "Compliance ",
      SecondTilte: " & Security",
      Icon: Adminpanelsettingsicon,
    },
    {
      Title: "Analytics",
      SecondTilte: " & Reporting",
      Icon: ReportingIcon,
    },
    {
      Title: "Marketing ",
      SecondTilte: "& Promotions",
      Icon: Marketingicon,
    },

  ];

  let CompanyFeaturesObject2 = [
    {
      Title: "Customer  ",
      SecondTilte: "Support",
      Icon: CustomerSupporticon,
    },
    {
      Title: "Multi-Platform ",
      SecondTilte: "Integration",
      Icon: MultiPlatformicon,
    },
    {
      Title: "Content ",
      SecondTilte: "Management System ",
      Icon: ContentManagmenticon,
    },
    {
      Title: "Scalability ",
      SecondTilte: "& Performance",
      Icon: Performenceicon,
    },
  ];

  let CompanyFeaturesObjectMobile1 = [
    {
      Title: "Game  ",
      SecondTilte: "Management",
      Icon: GameManagmenticon,
    },
    {
      Title: "User Account ",
      SecondTilte: "Management",
      Icon: UserAccounticon,
    },
  ];

  let CompanyFeaturesObjectMobile2 = [
    {
      Title: "Compliance ",
      SecondTilte: " & Security",
      Icon: Adminpanelsettingsicon,
    },
    {
      Title: "Analytics",
      SecondTilte: " & Reporting",
      Icon: ReportingIcon,
    },
  ];

  let CompanyFeaturesObjectMobile3 = [
    {
      Title: "Marketing ",
      SecondTilte: "& Promotions",
      Icon: Marketingicon,
    },
    {
      Title: "Customer  ",
      SecondTilte: "Support",
      Icon: CustomerSupporticon,
    },
  ];


  let CompanyFeaturesObjectMobile5 = [
    {
      Title: "Scalability ",
      SecondTilte: "& Performance",
      Icon: Performenceicon,
    },
  ];



  return (
    <div className="container">
      <div className={classes.allCont}>
        <div className={classes.featuresWrapper}>
          {CompanyFeaturesObject1 &&
            CompanyFeaturesObject1.map((item, index) => {
              return (
                <div className={classes.featuresInside}>
                  <div key={`feature-${index}`} className={classes.imageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="features Icons"
                      className={classes.featuresIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title}
                    <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.featuresWrapper1}>
          {CompanyFeaturesObject2 &&
            CompanyFeaturesObject2.map((item, index) => {
              return (
                <div className={classes.featuresInside}>
                  <div key={`feature-${index}`} className={classes.imageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="features Icons"
                      className={classes.featuresIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title}
                    <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.featuresWrapperMobile}>
          {CompanyFeaturesObjectMobile1 &&
            CompanyFeaturesObjectMobile1.map((item, index) => {
              return (
                <div className={classes.featuresInside}>
                  <div key={`feature-${index}`} className={classes.imageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="features Icons"
                      className={classes.featuresIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title}
                    <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.featuresWrapperMobile}>
          {CompanyFeaturesObjectMobile2 &&
            CompanyFeaturesObjectMobile2.map((item, index) => {
              return (
                <div className={classes.featuresInside}>
                  <div key={`feature-${index}`} className={classes.imageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="features Icons"
                      className={classes.featuresIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title}
                    <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.featuresWrapperMobile}>
          {CompanyFeaturesObjectMobile3 &&
            CompanyFeaturesObjectMobile3.map((item, index) => {
              return (
                <div className={classes.featuresInside}>
                  <div key={`feature-${index}`} className={classes.imageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="features Icons"
                      className={classes.featuresIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title}
                    <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.featuresWrapperMobile}>
          {CompanyFeaturesObjectMobile5 &&
            CompanyFeaturesObjectMobile5.map((item, index) => {
              return (
                <div className={classes.featuresInside}>
                  <div key={`feature-${index}`} className={classes.imageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="features Icons"
                      className={classes.featuresIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title}
                    <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default CompanyFeatures;
