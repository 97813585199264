import React from "react";
import MobiInfo from "./MobiInfo";
import OurValues from "./OurValues";
import classes from "./HomePage.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MainBanner from "../../components/MainBanner/MainBanner";
import PromoBanner from "../../components/PromoBanner/PromoBanner";
import Working from "../../assets/HomePageImages/man-working-table.png";
import HomePageBanner from "../../assets/HomePageImages/homePageBanner.png";
import { Link } from "react-router-dom";
import SliderTelecos from "../../components/SliderComponent/SliderTelecos";
import SliderPartners from "../../components/SliderComponent/SliderPartners";

function HomePage() {

  return (
    <>
      <Header />
      <MainBanner
        Title="Connecting Ideas"
        ColoredTitle="with Technology"
        ImageBanner={HomePageBanner}
      />
      <div className="container">
        <div className={classes.bothContainer}>
          <div className={classes.firstCont}>
            <span className={classes.titleStyle}>
              Why <span className={classes.secondTitle}>Us?</span>
            </span>
            <p className={classes.paragraph}>
              Since 2009, Moobitek has been providing innovative tech solutions
              to global markets. With a solid presence in Europe, the Middle
              East, and Africa, we collaborate with leading mobile operators,
              gaming companies, financial institutions, and other industry
              experts to deliver technology solutions that foster innovation and
              growth.
            </p>
            <Link to="/Aboutus" className={classes.readMoreButton}>
              Read More...
            </Link>
          </div>
          <div>
            <div className={classes.circleStyle}>
              <img
                src={Working}
                alt="Working"
                className={`${classes.imageStyle} ${classes.desktopImage}`}
              />
            </div>
          </div>
        </div>
      </div>
      <OurValues />
      <MobiInfo />
      <div className={classes.lineSeperate} />
      <div className={classes.partnerComponent1}>
        <div className={classes.partnerMobile1}>
          <span className={classes.ourPresesnceTitle1}>PARTNERS</span>
        </div>
        <span className={classes.subtitlesPartner}>
          Our commitment to excellence ensures that our clients stay ahead in a
          rapidly evolving digital landscape.
        </span>
        <SliderPartners />
      </div>
      <div className={classes.partnerComponent}>
        <div className={classes.partnerMobile}>
          <span className={classes.ourPresesnceTitle}>TELCOS</span>
        </div>
        <SliderTelecos />
      </div>
      <PromoBanner />
      <Footer />
    </>
  );
}

export default HomePage;
