import React from "react";
import classes from "./MoobiBanners.module.scss";

function MoobiBanners({ logoName, LogoIcon, style }) {
  const isMobiVault = style === "MobiVault";
  return (
    <div className={classes.allBanner}>
      <div className={classes.test}>
        {LogoIcon ?
          <img
            src={LogoIcon}
            alt="mobiIcons"
            className={`${classes.logoIcon} ${isMobiVault ? classes.mobiVaultLogoIcon : ''}`}
          /> : ""}
        <img
          src={logoName}
          alt="mobiNames"
          className={`${classes.logoName} ${isMobiVault ? classes.mobiVaultLogoName : ''}`}
        />
      </div>


      {LogoIcon ?
        <>
          <img src={LogoIcon} alt="mobiIcons" className={classes.logoIconRight} />
          <img src={LogoIcon} alt="mobiIcons" className={classes.logoIconLeft} />
        </> :
        <>
          <div className={`${classes.triangle} ${classes.firsttriangle}`} />
          <div className={`${classes.triangle} ${classes.secondtriangle}`} />
        </>
      }
    </div>
  );
}

export default MoobiBanners;
