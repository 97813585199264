import "./App.scss";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Aboutus from "./containers/AboutUs/AboutUs";
import MoobiPay from "./containers/MoobiPay/MoobiPay";
import HomePage from "./containers/HomePage/HomePage";
import Homepage from "./containers/HomePage/HomePage";
import MoobiDev from "./containers/MoobiDev/MoobiDev";
import ContactUs from "./containers/ContactUs/ContactUs";
import MoobiPlus from "./containers/MoobiPlus/MoobiPlus";
import MoobiVault from "./containers/MoobiVault/MoobiVault";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Publications from "./containers/Publications/Publications";

function handleScrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

function App() {
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollPosition = window.scrollY + window.innerHeight;
    const scrolledPercentage = (scrollPosition / scrollHeight) * 100;

    if (scrolledPercentage > 30) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Aboutus" element={<Aboutus />} />
        <Route path="/" element={<Homepage />} />
        <Route path="/Publications" element={<Publications />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/MoobiDev" element={<MoobiDev />} />
        <Route path="/MoobiPlus" element={<MoobiPlus />} />
        <Route path="/MoobiPay" element={<MoobiPay />} />
        <Route path="/MoobiVault" element={<MoobiVault />} />
      </Routes>
      {isVisible && isMobile && (
        <div onClick={handleScrollToTop} className="btnScrolTop">
          <FontAwesomeIcon icon={faChevronUp} className="arrowStyle" />
        </div>
      )}
    </div>
  );
}

export default App;
