import React, { useState } from "react";
import classes from "./PromoBanner.module.scss";
import MoobitekLetter from "../../assets/MoobitekLogos/mLetter.svg";
import axios from "axios";
import Loader from "../Loader/Loader";

function PromoBanner() {
  const [message, setMessage] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [workEmail, setWorkemail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formMessage, setFormMessage] = useState({ message: "", type: "" });
  const { REACT_APP_API_ENDPOINT } = process.env;
  const type = "Quotation";

  const submit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (
      firstName.trim() === "" &&
      lastName.trim() === "" &&
      workEmail.trim() === "" &&
      phoneNumber.trim() === "" &&
      message.trim() === ""
    ) {
      setFormMessage({
        message: "All fields must be filled and valid.",
        type: "error",
      });
      setIsLoading(false);
      return;
    }
    axios({
      method: "POST",
      url: REACT_APP_API_ENDPOINT,
      data: {
        messageHtml: `<div><p>FirstName:${firstName}</p><p>LastName:${lastName}</p><p>WorkEmail:${workEmail}</p><p>PhoneNumber:${phoneNumber}</p></div>`,
        type,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.msg === "success") {
          setFormMessage({ message: "Email sent!", type: "success" });
          resetForm();
        } else if (response.data.msg === "fail") {
          setFormMessage({
            message: "Oops, something went wrong. Try again",
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setFormMessage({
          message: "Oops, something went wrong. Please try again later.",
          type: "error",
        });
        setIsLoading(false);
      });
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setWorkemail("");
    setPhoneNumber("");
    setMessage("");
  };

  return (
    <div className={classes.allCont}>
      <div className={classes.firstCont}>
        <div className={`${classes.triangle} ${classes.right}`} />
        <div className={classes.triangle3} />
        <div className={classes.checkBtn}>
          <div className={classes.btnInner}>
            <span className={classes.btnText}>
              CHECK OUT
              <br /> OUR DEMO’s
            </span>
          </div>
        </div>
      </div>

      <form className={classes.secondCont} onSubmit={submit}>
        <div className={`${classes.triangle} ${classes.left}`} />
        <div className={classes.rectangle}>
          <div className={classes.infoInside}>
            <img
              src={MoobitekLetter}
              alt="mobiLetter"
              className={classes.mLogoLetter}
            />
            <div className={classes.titleContainer}>
              <span className={classes.title}>
                Let's chat about
                <span className={classes.subTitle}>your project.</span>
              </span>
            </div>
            <div className={classes.inputsPosition}>
              <input
                className={classes.inputFields}
                placeholder="First name*"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                type="text"
              />
              <input
                className={classes.inputFields}
                placeholder="Last name*"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
              />
            </div>
            <div className={classes.inputsPosition}>
              <input
                className={classes.inputFields}
                placeholder="Work Email*"
                value={workEmail}
                onChange={(e) => setWorkemail(e.target.value)}
                type="email"
              />
              <input
                className={classes.inputFields}
                placeholder="Phone number*"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                type="numeric"
              />
            </div>
            <div className={classes.checkBoxBtn}>
              <div className={classes.btnPosition}>
                <button
                  className={classes.btnStyle}
                  type="submit"
                  disabled={isLoading}
                >
                  Schedule Consultation
                </button>
                <div style={{ position: "absolute", right: "15%" }}>
                  {isLoading && <Loader />}
                </div>
                <div className={classes.messagePosition}>
                  {formMessage.message && (
                    <div className={classes.message}>{formMessage.message}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PromoBanner;
