import React from "react";
import OurOffices from "./OurOffices";
import ProductTeam from "./ProductTeam";
import classes from "./ContactUs.module.scss";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import MainBanner from "../../components/MainBanner/MainBanner";
import PromoBanner from "../../components/PromoBanner/PromoBanner";
import ContactUsBanner from "../../assets/ContactUsImages/ContactUsImage.png";

function ContactUs() {
  const supportEmail = "Support@moobitek.com";
  return (
    <div>
      <Header />
      <MainBanner
        topChange="titleSubTitle"
        Secondtitle="Get in "
        SecondcoloredTitle="Touch"
        subTitle=" We’re ready to become your personal guide through the software
        experience as we bring your project to life."
        ImageBanner={ContactUsBanner}
      />
      <div className="container">
        <div className={classes.productTeamPosition}>
          <ProductTeam />
        </div>
        <div className={classes.emailPosition}>
          <span className={classes.emailSentence}>
            Don’t have a project to discuss quite yet? Or, want to reach out
            about something else? You’re still welcome <br /> to email
            <a
              href={`mailto:${supportEmail}`}
              className={classes.emailSentenceSecond}
            >
              {supportEmail}
            </a>
            any time you want to chat.
          </span>
        </div>
      </div>
      <OurOffices />
      <PromoBanner />
      <Footer />
    </div>
  );
}

export default ContactUs;
