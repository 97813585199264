import React from "react";
import classes from "./MoobiVault.module.scss";
import ExpandedIcon from "../../assets/MoobiVaultImages/Expanded_Icon.svg";
import EffeciantIcon from "../../assets/MoobiVaultImages/Effeciant_Icon.svg";
import HandImageicon from "../../assets/MoobiVaultImages/HandImage_icon.svg";
import DataDrivenIcon from "../../assets/MoobiVaultImages/DataDriven_Icon.svg";
import StreamLinedIcon from "../../assets/MoobiVaultImages/StreamLined_Icon.svg";
import CustomizableIcon from "../../assets/MoobiVaultImages/Customizable_Icon.svg";
import RiskAssesmentIcon from "../../assets/MoobiVaultImages/RiskAssesment_Icon.svg";
import RevenueOpportunityIcon from "../../assets/MoobiVaultImages/RevenueOpportunity_Icon.svg";
import EnhancedCustomerLoyalityIcon from "../../assets/MoobiVaultImages/EnhancedCustomerLoyality_icon.svg";
import EnhancedCustomerExperience from "../../assets/MoobiVaultImages/EnhancedCustomerExperience_Icon.svg";

function BusinessContainer() {
  let BusinessObject1 = [
    {
      Title: "Streamlined Loan  ",
      SecondTilte: "Management",
      Icon: StreamLinedIcon,
      position: "top"
    },
    {
      Title: "Enhanced Customer  ",
      SecondTilte: "Experience",
      Icon: EnhancedCustomerExperience,
    },
    {
      Title: "Expanded  ",
      SecondTilte: "Customer Base",
      Icon: ExpandedIcon,
    },
  ];

  let BusinessObject2 = [
    {
      Title: "Data-driven Credit  ",
      SecondTilte: "Decisioning",
      Icon: DataDrivenIcon,
    },
    {
      Title: "Enhanced ",
      SecondTilte: "Customer Loyalty ",
      Icon: EnhancedCustomerLoyalityIcon,
    },
    {
      Title: "Increased Revenue  ",
      SecondTilte: "Opportunity",
      Icon: RevenueOpportunityIcon,
    },
  ];

  let BusinessObject3 = [
    {
      Title: "Improved Risk  ",
      SecondTilte: "Assessment",
      Icon: RiskAssesmentIcon,
      position: "bottom"
    },
    {
      Title: "Customizable  ",
      SecondTilte: "Financing Options",
      Icon: CustomizableIcon,
    },
    {
      Title: "Efficient Collection  ",
      SecondTilte: "Process",
      Icon: EffeciantIcon,
    },
  ];

  let BusinessObjectMobile1 = [
    {
      Title: "Streamlined Loan  ",
      SecondTilte: "Management",
      Icon: StreamLinedIcon,
      position: "block1"
    },
    {
      Title: "Enhanced Customer  ",
      SecondTilte: "Experience",
      Icon: EnhancedCustomerExperience,
      position: "none"

    },
  ];

  let BusinessObjectMobile2 = [
    {
      Title: "Expanded  ",
      SecondTilte: "Customer Base",
      Icon: ExpandedIcon,
      position: "block2"

    },
    {
      Title: "Data-driven Credit  ",
      SecondTilte: "Decisioning",
      Icon: DataDrivenIcon,
      position: "none"

    },
  ];

  let BusinessObjectMobile3 = [
    {
      Title: "Enhanced ",
      SecondTilte: "Customer Loyalty ",
      Icon: EnhancedCustomerLoyalityIcon,
      position: "block3"

    },
    {
      Title: "Increased Revenue  ",
      SecondTilte: "Opportunity",
      Icon: RevenueOpportunityIcon,
      position: "none"

    },
  ];

  let BusinessObjectMobile4 = [
    {
      Title: "Improved Risk  ",
      SecondTilte: "Assessment",
      Icon: RiskAssesmentIcon,
      position: "block4"

    },
    {
      Title: "Customizable  ",
      SecondTilte: "Financing Options",
      Icon: CustomizableIcon,
      position: "none"

    },
  ];

  let BusinessObjectMobile5 = [
    {
      Title: "Efficient Collection  ",
      SecondTilte: "Process",
      Icon: EffeciantIcon,
      position: "block5"

    },
  ];



  return (
    <div className={classes.businessCircles}>
      <div className={classes.bigCircleBusiness}>
        <div className={classes.leftCircleStyle}>
          <img
            src={HandImageicon}
            alt="hand holding"
            className={classes.payLaterImage}
          />
        </div>
      </div>
      <div className={classes.allBusinessDigital}>
        <div className={classes.businessWrapper}>
          {BusinessObject1 &&
            BusinessObject1.map((item, index) => {
              return (
                <>
                  <div key={index} className={classes.arowContainer}>
                    <div className={`${classes.arrowStyleBusiness} ${classes[item.position]}`}
                    />
                  </div>
                  <div className={classes.iconTitle}>
                    <div>
                      <div className={classes.BusinessimageTitleCont}>
                        <img
                          src={item.Icon}
                          alt="Business"
                          className={classes.LoanIcons}
                        />
                      </div>
                      <div>
                        <span className={classes.titleUnderIcon}>
                          {item.Title} <br />
                          <span>{item.SecondTilte}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <div className={classes.businessWrapper}>
          {BusinessObject2 &&
            BusinessObject2.map((item, index) => {
              return (
                <>
                  <div key={index} className={classes.arowContainer}>
                    <div className={`${classes.arrowStyleBusiness} ${classes[item.position]}`}
                    />
                  </div>
                  <div className={classes.iconTitle}>
                    <div>
                      <div className={classes.BusinessimageTitleCont}>
                        <img
                          src={item.Icon}
                          alt="Business"
                          className={classes.LoanIcons}
                        />
                      </div>
                      <div>
                        <span className={classes.titleUnderIcon}>
                          {item.Title} <br />
                          <span>{item.SecondTilte}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>

        <div className={classes.businessWrapper}>
          {BusinessObject3 &&
            BusinessObject3.map((item, index) => {
              return (
                <>
                  <div key={index} className={classes.arowContainer}>
                    <div className={`${classes.arrowStyleBusiness} ${classes[item.position]}`}
                    />
                  </div>
                  <div className={classes.iconTitle}>
                    <div>
                      <div className={classes.BusinessimageTitleCont}>
                        <img
                          src={item.Icon}
                          alt="Business"
                          className={classes.LoanIcons}
                        />
                      </div>
                      <div>
                        <span className={classes.titleUnderIcon}>
                          {item.Title} <br />
                          <span>{item.SecondTilte}</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
        <div className={classes.BusinessWrapperMobile}>
          {BusinessObjectMobile1 &&
            BusinessObjectMobile1.map((item, index) => {
              return (
                <>
                  <div className={classes.test}>
                    <div key={index} className={classes.BusinessimageTitleCont}>
                      <img
                        src={item.Icon}
                        alt="Business "
                        className={classes.LoanIcons}
                      />
                    </div>
                    <span className={classes.titleUnderIcon}>
                      {item.Title} <br />
                      <span>{item.SecondTilte}</span>
                    </span>


                  </div>
                  <div className={classes.test} >
                    <div className={`${classes.arrowStyleBusiness} ${classes[item.position]}`}
                    />
                  </div>

                </>
              );
            })}

        </div>
        <div className={classes.BusinessWrapperMobile}>
          {BusinessObjectMobile2 &&
            BusinessObjectMobile2.map((item, index) => {
              return (
                <>
                  <div>
                    <div key={index} className={classes.BusinessimageTitleCont}>
                      <img
                        src={item.Icon}
                        alt="Business "
                        className={classes.LoanIcons}
                      />
                    </div>
                    <span className={classes.titleUnderIcon}>
                      {item.Title} <br />
                      <span>{item.SecondTilte}</span>
                    </span>
                  </div>
                  <div className={classes.test} >
                    <div className={`${classes.arrowStyleBusiness} ${classes[item.position]}`}
                    />
                  </div>
                </>
              );
            })}
        </div>
        <div className={classes.BusinessWrapperMobile}>
          {BusinessObjectMobile3 &&
            BusinessObjectMobile3.map((item, index) => {
              return (
                <>
                  <div>
                    <div key={index} className={classes.BusinessimageTitleCont}>
                      <img
                        src={item.Icon}
                        alt="Business "
                        className={classes.LoanIcons}
                      />
                    </div>
                    <span className={classes.titleUnderIcon}>
                      {item.Title} <br />
                      <span>{item.SecondTilte}</span>
                    </span>
                  </div>
                  <div className={classes.test} >
                    <div className={`${classes.arrowStyleBusiness} ${classes[item.position]}`}
                    />
                  </div>
                </>
              );
            })}
        </div>
        <div className={classes.BusinessWrapperMobile}>
          {BusinessObjectMobile4 &&
            BusinessObjectMobile4.map((item, index) => {
              return (
                <>
                <div>
                  <div key={index} className={classes.BusinessimageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="Business "
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
                <div className={classes.test} >
                    <div className={`${classes.arrowStyleBusiness} ${classes[item.position]}`}
                    />
                  </div>
                </>
              );
            })}
        </div>
        <div className={classes.BusinessWrapperMobile}>
          {BusinessObjectMobile5 &&
            BusinessObjectMobile5.map((item, index) => {
              return (
                <div>
                  <div key={index} className={classes.BusinessimageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="Business "
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                  <div className={`${classes.arrowStyleBusiness} ${classes[item.position]}`}
                  />

                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default BusinessContainer;
