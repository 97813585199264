import React from "react";
import CompanyGames from "./CompanyGames";
import CompanyFeatures from "./CompanyFeatures";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import PromoBanner from "../../components/PromoBanner/PromoBanner";
import MoobiBanners from "../../components/MoobiBanners/MoobiBanners";
import MobiPlusLogo from "../../assets/MoobiPlusImages/MoobiPlus_logo.svg";
import MobiPlusimage from "../../assets/MoobiPlusImages/MobiPlus_image.svg";
import MobiPlusName from "../../assets/MoobiPlusImages/MobiPlusName_logo.svg";
import OverViewComponent from "../../components/OverViewComponent/OverViewComponent";

function MoobiPlus() {
  return (
    <>
      <Header />
      <MoobiBanners logoName={MobiPlusName} LogoIcon={MobiPlusLogo} />
      <OverViewComponent
        image={MobiPlusimage}
        paragraph="  Streamline your online gaming operations with our all-in-one
            platform. Manage games, user accounts, payments, and compliance
            effortlessly. Boost growth with powerful marketing tools, detailed
            analytics, and top-notch security—all in one solution designed for
            success."
        downContainer={true}
      />
      <CompanyFeatures />
      <CompanyGames />
      <PromoBanner />
      <Footer />
    </>
  );
}

export default MoobiPlus;
