import React from "react";
import classes from "./MoobiPay.module.scss";
import Analyticsicon from "../../assets/MoobiPayImages/Analytics_icon.svg";
import InstantPayicon from "../../assets/MoobiPayImages/InstantPay_icon.svg";
import ApiIntegrationicon from "../../assets/MoobiPayImages/ApiIntegration_icon.svg";
import MultiplePaymenticon from "../../assets/MoobiPayImages/MultiplePayment_icon.svg";
import HighAvailabilityicon from "../../assets/MoobiPayImages/HighAvailability_icon.svg";
import MultiCurrencyiconsvg from "../../assets/MoobiPayImages/MultiCurrency_iconsvg.svg";
import Instanttransactionicon from "../../assets/MoobiPayImages/Instanttransaction_icon.svg";

function CoreFeatures() {
  let CoreFeatures1 = [
    {
      Title: "Multi-Currency Support ",
      Icon: MultiCurrencyiconsvg,
    },
    {
      Title:
        "Multiple Payment Methods (Credit/Debit Cards, Digital Wallets,Bank Transfers and Mobile Money)",
      Icon: MultiplePaymenticon,
    },
    {
      Title: "Instant Payouts ",
      Icon: InstantPayicon,
    },
    {
      Title: "Instant Transaction Confirmation ",
      Icon: Instanttransactionicon,
    },

  ];

  let CoreFeatures2 = [
    {
      Title: "High Availability ",
      Icon: HighAvailabilityicon,
    },
    {
      Title: "API Integration",
      Icon: ApiIntegrationicon,
    },
    {
      Title: "Analytics and Reporting ",
      Icon: Analyticsicon,
    },
  ];

  let CoreFeaturesMobile1 = [
    {
      Title: "Multi-Currency Support ",
      Icon: MultiCurrencyiconsvg,
    },
    {
      Title:
        "Multiple Payment Methods (Credit/Debit Cards, Digital Wallets,Bank Transfers and Mobile Money)",
      Icon: MultiplePaymenticon,
    },
  ];

  let CoreFeaturesMobile2 = [
    {
      Title: "Instant Payouts ",
      Icon: InstantPayicon,
    },
    {
      Title: "Instant Transaction Confirmation ",
      Icon: Instanttransactionicon,
    },
  ];

  let CoreFeaturesMobile3 = [
    {
      Title: "High Availability ",
      Icon: HighAvailabilityicon,
    },
    {
      Title: "API Integration",
      Icon: ApiIntegrationicon,
    },
  ];

  let CoreFeaturesMobile4 = [
    {
      Title: "Analytics and Reporting ",
      Icon: Analyticsicon,
    },
  ];

  return (
    <div className={classes.allComponent}>
        <span className={classes.mainTitle}>
          Core<span className={classes.secondMainTitle}>Features</span>
        </span>
        <div className={classes.coreFeaturesWrapper}>
          {CoreFeatures1 &&
            CoreFeatures1.map((item, index) => {
              return (
                <div key={index} className={classes.coreFeaturesinfoInside}>
                  <div className={classes.coreFeaturesCircle}>
                    <img
                      src={item.Icon}
                      alt="coreFeatures Icons"
                      className={classes.coreFeaturesIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>{item.Title}</span>
                </div>
              );
            })}
        </div>
        <div className={classes.coreFeaturesWrapper1}>
          {CoreFeatures2 &&
            CoreFeatures2.map((item, index) => {
              return (
                <div key={index} className={classes.coreFeaturesinfoInside1}>
                  <div className={classes.coreFeaturesCircle}>
                    <img
                      src={item.Icon}
                      alt="coreFeatures Icons"
                      className={classes.coreFeaturesIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>{item.Title}</span>
                </div>
              );
            })}
        </div>

        <div className={classes.coreFeaturesWrapperMobile1}>
          {CoreFeaturesMobile1 &&
            CoreFeaturesMobile1.map((item, index) => {
              return (
                <div key={index} className={classes.coreFeaturesinfoInside1}>
                  <div className={classes.coreFeaturesCircle}>
                    <img
                      src={item.Icon}
                      alt="coreFeatures Icons"
                      className={classes.coreFeaturesIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>{item.Title}</span>
                </div>
              );
            })}
        </div>

        <div className={classes.coreFeaturesWrapperMobile1}>
          {CoreFeaturesMobile2 &&
            CoreFeaturesMobile2.map((item, index) => {
              return (
                <div key={index} className={classes.coreFeaturesinfoInside1}>
                  <div className={classes.coreFeaturesCircle}>
                    <img
                      src={item.Icon}
                      alt="coreFeatures Icons"
                      className={classes.coreFeaturesIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>{item.Title}</span>
                </div>
              );
            })}
        </div>
        <div className={classes.coreFeaturesWrapperMobile1}>
          {CoreFeaturesMobile3 &&
            CoreFeaturesMobile3.map((item, index) => {
              return (
                <div key={index} className={classes.coreFeaturesinfoInside1}>
                  <div className={classes.coreFeaturesCircle}>
                    <img
                      src={item.Icon}
                      alt="coreFeatures Icons"
                      className={classes.coreFeaturesIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>{item.Title}</span>
                </div>
              );
            })}
        </div>
        <div className={classes.coreFeaturesWrapperMobile1}>
          {CoreFeaturesMobile4 &&
            CoreFeaturesMobile4.map((item, index) => {
              return (
                <div key={index} className={classes.coreFeaturesinfoInside1}>
                  <div className={classes.coreFeaturesCircle}>
                    <img
                      src={item.Icon}
                      alt="coreFeatures Icons"
                      className={classes.coreFeaturesIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>{item.Title}</span>
                </div>
              );
            })}
        </div>
    </div>
  );
}

export default CoreFeatures;
