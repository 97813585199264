import React from "react";
import classes from "./OverViewComponent.module.scss";
import digitalPaymentIcon from "../../assets/MoobiPlusImages/digitalPayment_Icon.svg";
import OmniChannelIcon from "../../assets/MoobiPlusImages/omniChannel_Icon.svg";

function OverViewComponent({ image, paragraph, downContainer }) {
  let overVewiObject = [
    {
      Title: "Omnichannel",
      Icon: OmniChannelIcon,
      subtitles: ["USSD", "Web", "SMS", "Mobile App"],
    },
    {
      Title: "Digital payment  ",
      secondTitle: "Processing",
      Icon: digitalPaymentIcon,
      subtitles: ["E-wallets", "Mobile Money", "Payment Gateway", "Banks"],
    },
  ];

  return (
    <div className="container">
      <div className={classes.overViewComponent}>
        <div className={classes.overViewContainer}>
          <span className={classes.overVeiwTitle}>
            Over
            <span className={classes.overVeiwSecondTitle}>view</span>
          </span>
          <p className={classes.overVeiwParagraph}>{paragraph}</p>
        </div>
        <div className={classes.ImageContainer}>
          <div className={classes.overVeiwImage}>
            <img src={image} alt="" className={classes.imageStyle}
            />
          </div>
        </div>
      </div>
      {downContainer ? (
        <div className={classes.overVewiWrapper}>
          {overVewiObject &&
            overVewiObject.map((item, index) => {
              return (
                <div className={classes.iconArrowsStyle}>
                  <div key={` overVewi-${index}`} className={classes.overVewiSmallCircles}>
                    <img
                      src={item.Icon}
                      alt="OmniChannel Icon"
                      className={classes.smallImageStyle}
                    />

                    <span
                      className={`${classes.titleUnderIcon} ${item.Title === "Omnichannel"
                        ? classes.firstTitle
                        : classes.secondTitle
                        }`}
                    >
                      {item.Title} <br />
                      <span className={classes.secondTitleText}>
                        {item.secondTitle}
                      </span>
                    </span>
                    <div
                      className={`${classes.arrowStyle} ${classes.arrow1}`}
                    />
                    <div
                      className={`${classes.arrowStyle} ${classes.arrow2}`}
                    />
                    <div
                      className={`${classes.arrowStyle} ${classes.arrow3}`}
                    />
                    <div
                      className={`${classes.arrowStyle} ${classes.arrow4}`}
                    />
                  </div>
                  <div className={classes.arrowContMobile}>

                    <div
                      className={`${classes.arrowStyleMobile} ${classes.arrowMobile2}`}
                    />
                  </div>
                  <div className={classes.subTitlesLeftPosition}>
                    {item.subtitles.map((subtitle, subtitleIndex) => {
                      let subtitleClass = "";
                      if (subtitle === "E-wallets") {
                        subtitleClass = classes.eWalletSubtitle;
                      } else if (subtitle === "Mobile Money") {
                        subtitleClass = classes.mobileMoneySubtitle;
                      } else if (subtitle === "Payment Gateway") {
                        subtitleClass = classes.paymentGatewaySubtitle;
                      } else if (subtitle === "Banks") {
                        subtitleClass = classes.banksSubtitle;
                      }
                      return (
                        <span
                          key={subtitleIndex}
                          className={`${classes.subtitleBesideArrow} ${classes[`subtitle${subtitleIndex + 1}`]
                            } ${subtitleClass}`}
                        >
                          {subtitle}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default OverViewComponent;
