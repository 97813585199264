import { Link } from "react-router-dom";
import classes from "./Header.module.scss";
import React, { useEffect, useState } from "react";
import MobiPay from "../../assets/HeaderIcons/Mobi-pay.svg";
import MobiFin from "../../assets/HeaderIcons/Mobi-FIn.svg";
import MobiDiv from "../../assets/HeaderIcons/Mobi-Dev.svg";
import MobiPlus from "../../assets/HeaderIcons/Mobi-Plus.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import moobitekLogo from "../../assets/MoobitekLogos/MoobitekFinalLogowhite.png";
import {
  faFacebookF,
  faXTwitter,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

function Header() {
  const [btnstate, setbtnstate] = useState("");
  const [menulist, setmenulist] = useState("hidden");
  const [dropDown, setDropDown] = useState("hidden");
  const [mobilemenu, setmobilemenu] = useState("closed");

  useEffect(() => {
    if (menulist === "visible") {
      document.body.classList.add("bodyOverFlow");
    } else if (menulist === "hidden") {
      document.body.classList.remove("bodyOverFlow");
    }
  }, [menulist]);

  const openmenu = () => {
    if (mobilemenu === "closed") {
      setmobilemenu("open");
      setbtnstate("open");
      setmenulist("visible");
    } else {
      setmobilemenu("closed");
      setbtnstate("closed");
      setmenulist("hidden");
    }
  };


  const openDropDown = () => {
    setDropDown((currentValue) =>
      currentValue === "visible" ? "hidden" : "visible"
    );
  };

  return (
    <header className={classes.header}>
      <div className={classes.topHeader}>
        <Link to="/" className={classes.logoMobile}>
          <img
            src={moobitekLogo}
            alt="moobitek Logo "
            className={classes.logo}
          />
        </Link>
        <div className={classes.burgermenuContainer} onClick={openmenu}>
          <i
            className={`${btnstate === "open" ? classes.openbtn : classes.closebtn
              }`}
          ></i>
          <i
            className={`${btnstate === "open" ? classes.openbtn : classes.closebtn
              }`}
          ></i>
          <i
            className={`${btnstate === "open" ? classes.openbtn : classes.closebtn
              }`}
          ></i>
        </div>

        <nav
          className={`${classes.menulist} ${menulist === "visible" ? classes.open : classes.close
            }`}
        >
          <div
            className={`${classes.BlacklineSeperate} ${classes.firstLine}`}
          />
          <ul className={classes.ul_list}>
            <li className={classes.navigateTabs}>
              <Link to="/AboutUs" className={classes.list_word}>
                Why Moobitek
              </Link>
            </li>
            <li className={classes.navigateTabs} onClick={openDropDown}>
              <div
                className={`${classes.list_word} ${dropDown === "visible" ? classes.noSpaceStyleVerticales : ""
                  }`}
              >
                <span style={{ marginRight: "13px", cursor: "pointer" }}>
                  Verticales
                </span>
                <FontAwesomeIcon
                  className={`${classes.iconClose} ${dropDown === "visible" ? classes.up : ""
                    }`}
                  icon={faChevronDown}
                />
              </div>
              <div
                className={`${classes.dropdownContent} ${dropDown === "visible" ? classes.visible : ""
                  }`}
              >
                <ul className={classes.dropDownList}>
                  <li className={classes.listWordList}>
                    <Link to="/MoobiPlus" className={classes.linkStyle}>
                      <img
                        src={MobiPlus}
                        className={classes.logoIcon}
                        alt="mobiPlus Icon"
                      />
                      MoobiPlus
                    </Link>
                  </li>
                  <div className={classes.lineSeperateList} />
                  <li className={classes.listWordList}>
                    <Link to="/MoobiPay" className={classes.linkStyle1}>
                      <img
                        src={MobiPay}
                        className={classes.logoIcon}
                        alt="MoobiPay Icon"
                      />
                      MoobiPay
                    </Link>
                  </li>
                  <div className={classes.lineSeperateList} />

                  <li className={classes.listWordList}>
                    <Link to="/MoobiVault" className={classes.linkStyle1}>
                      <img
                        src={MobiFin}
                        className={classes.logoIcon}
                        alt="MoobiVault Icon"
                      />
                      MoobiVault
                    </Link>
                  </li>
                  <div className={classes.lineSeperateList} />

                  <li className={classes.listWordList}>
                    <Link to="/MoobiDev" className={classes.linkStyle1}>
                      <img
                        src={MobiDiv}
                        className={classes.logoIcon}
                        alt="MoobiDev Icon"
                      />
                      MoobiDev
                    </Link>
                  </li>
                  <div className={classes.lineSeperateListEnd} />

                </ul>
              </div>
            </li>

            <li className={classes.navigateTabs}>
              <Link to="/Publications" className={`${classes.list_word} ${dropDown === "visible" ? classes.noSpaceStylePublications : ""
                }`}>
                Publications
              </Link>
            </li>
            <li className={classes.navigateTabs}>
              <Link to="/ContactUs" className={`${classes.list_word} ${dropDown === "visible" ? classes.noSpaceStyleContactUs : ""
                }`}>
                Contact Us
              </Link>
            </li>
          </ul>
          <div className={classes.footerStyle}>
            <div
              className={`${classes.BlacklineSeperate} ${classes.secondLine}`}
            />
            <div className={classes.mediaPositions}>
              <a href="https://www.facebook.com/Moobitek" target="_blank" rel="noreferrer">
                <FontAwesomeIcon
                  icon={faFacebookF}
                  color="white"
                  className={classes.mediaIcon}
                />
              </a>
              <a
                href="https://twitter.com/i/flow/login?redirect_after_login=%2Fmoobitek"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faXTwitter}
                  color="white"
                  className={classes.mediaIcon}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/moobitek"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon
                  icon={faLinkedin}
                  color="white"
                  className={classes.mediaIcon}
                />
              </a>
              <a href="https://www.instagram.com/moobitek.leb" target="_blank" rel="noreferrer">
                <FontAwesomeIcon
                  icon={faInstagram}
                  color="white"
                  className={classes.mediaIcon}
                />
              </a>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}

export default Header;