import React from "react";
import classes from "./HomePage.module.scss";
import MobiDevLogo from "../../assets/HomePageImages/MoobiDevLog_desktop.svg";
import MobiPayLogo from "../../assets/HomePageImages/MoobiPayLogo_desktop.svg";
import MobiPayName from "../../assets/HomePageImages/MoobipayName_desktop.svg";
import MobiVaultLogo from "../../assets/HomePageImages/MoobiVaultLogo_desktop.svg";
import MobiVaultName from "../../assets/HomePageImages/MoobiVaultName_desktop.svg";
import MobiDevName from "../../assets/HomePageImages/MoobiDevName_desktop.svg";
import MobiPlusLogo from "../../assets/HomePageImages/MoobiPlusLogo_desktop.svg";
import MobiPlusName from "../../assets/HomePageImages/MoobiPlusName_desktop.svg";
import MobiVaultLogoMobile from "../../assets/HomePageImages/MoobiVaultLogo_mobile.svg";
import MobiVaultNameMobile from "../../assets/HomePageImages/MoobiVaultName_mobile.svg";
import MobiPayLogoMobile from "../../assets/HomePageImages/MoobiPayLogo_mobile.svg";
import MobiPayNameMobile from "../../assets/HomePageImages/MoobiPayName_mobile.svg";
import MobiDevLogoMobile from "../../assets/HomePageImages/MoobiDevLogo_mobile.svg";
import MobiDevNameMobile from "../../assets/HomePageImages/MoobiDevName_mobile.svg";
import MobiPlusLogoMobile from "../../assets/HomePageImages/MoobiPlusLogo-mobile.svg";
import MobiPlusNameMobile from "../../assets/HomePageImages/MoobiPlusName_mobile.svg";
import { Link } from "react-router-dom";

function MobiInfo() {
  let mobiInfoObject = [
    {
      Icon: MobiPlusLogo,
      mobileIcon: MobiPlusLogoMobile,
      imageName: MobiPlusName,
      mobileImageName: MobiPlusNameMobile,
      Paragraph:
        "Explore a seamless gaming experience with Moobitek’ trusted digital gaming platform starring worldwide content and cutting-edge features designed to improve your gameplay.",
      link: "/MoobiPlus",
    },
    {
      Icon: MobiPayLogo,
      mobileIcon: MobiPayLogoMobile,
      imageName: MobiPayName,
      mobileImageName: MobiPayNameMobile,
      Paragraph:
        "Discover the one-stop shop for seamless payment gateways and integration solutions, connecting the world with effortless and reliable payment options.",
      link: "/MoobiPay",
    },
    {
      Icon: MobiVaultLogo,
      mobileIcon: MobiVaultLogoMobile,
      imageName: MobiVaultName,
      mobileImageName: MobiVaultNameMobile,
      Paragraph:
        "Upgrade your lending experience and improve your financial journey with Moobitek’s advanced fintech solutions.",
      link: "/MoobiVault", 
    },
    {
      Icon: MobiDevLogo,
      mobileIcon: MobiDevLogoMobile,
      imageName: MobiDevName,
      mobileImageName: MobiDevNameMobile,
      Paragraph:
        "A partner providing end-to-end development services tailored to your unique business needs.",
      link: "/MoobiDev",
    },
  ];

  return (
    <div className={classes.mobiInfoContainer}>
      <div className={classes.backGroudnImage} />
      <div className="container">
        <div className={classes.mobiInfoInside}>
          {mobiInfoObject &&
            mobiInfoObject.map((item, index) => {
              return (
                <div key={index} className={classes.SquareTitleCont}>
                  <div className={classes.squareItem}>
                    <img
                      src={item.Icon}
                      alt="mobiInfo Logos"
                      className={`${classes.iconInsideMobiInfo} ${classes.desktopImageMobiInfo}`}
                    />
                    <img
                      src={item.mobileIcon}
                      alt="mobiInfo Logos Mobile"
                      className={`${classes.iconInsideMobiInfo} ${classes.mobileImageMobiInfo}`}
                    />
                  </div>
                  <img
                    src={item.imageName}
                    alt="mobiInfo name Logos"
                    className={`${classes.nameLogo} ${classes.desktopNameLogo}`}
                  />
                  <img
                    src={item.mobileImageName}
                    alt="mobiInfo name Logos Mobile"
                    className={`${classes.nameLogo} ${classes.mobileNameLogo}`}
                  />
                  <p className={classes.paragraphStyle}>{item.Paragraph}</p>
                  <Link to={item.link}>
                    <button className={classes.btnStyle}>Learn More</button>
                  </Link>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default MobiInfo;
