import React from "react";
import classes from "./HomePage.module.scss";
import Lamp from "../../assets/HomePageImages/Innovation_HomePage.svg";
import ShakeHands from "../../assets/HomePageImages/ShakeHands_HomePage.svg";
import CustomerFocus from "../../assets/HomePageImages/Customer_homePage.svg";
import Inclusivity from "../../assets/HomePageImages/Inclusivity_homepage.svg";

function OurValues() {
  let ourValuesObject = [
    {
      TitleFirst: "Integrity & ",
      TitleSecond: "Transparency",
      Icon: ShakeHands,
    },
    {
      TitleFirst: "Innovation &",
      TitleSecond: "Excellence",
      Icon: Lamp,
    },
    {
      TitleFirst: "Customer Focus &",
      TitleSecond: "Collaboration",
      Icon: CustomerFocus,
    },
    {
      TitleFirst: "Inclusivity &",
      TitleSecond: "Sustainability",
      Icon: Inclusivity,
    },
  ];
  return (
    <div className={classes.ourValuesContainer}>
      <div className="container">
        <div>
          <span className={classes.titleValues}>OUR VALUES</span>
        </div>
        <div className={classes.valuesWrapper}>
          {ourValuesObject &&
            ourValuesObject.map((item, index) => {
              return (
                <div key={index} className={classes.imageTitleCont}>
                  <div className={classes.circlesItem}>
                    <img
                      src={item.Icon}
                      alt="ourValues Logos"
                      className={classes.iconInside}
                    />
                  </div>
                  <span className={classes.firstTitleValue}>
                    {item.TitleFirst} <br />
                    <span className={classes.secondTitleValue}>
                      {item.TitleSecond}
                    </span>
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default OurValues;
