import React from "react";
import classes from "./MoobiVault.module.scss";
import AgentAppicon from "../../assets/MoobiVaultImages/AgentApp_icon.svg";
import ClientAppIconr from "../../assets/MoobiVaultImages/ClientApp_Iconr.svg";
import MerchantAppicon from "../../assets/MoobiVaultImages/MerchantApp_icon.svg";
import fasterTransicon from "../../assets/MoobiVaultImages/fasterTrans_icon.svg";
import PhysicalCardsicon from "../../assets/MoobiVaultImages/PhysicalCards_icon.svg";
import PaymentProcessicon from "../../assets/MoobiVaultImages/PaymentProcess_icon.svg";
import CustomerEngagmenticon from "../../assets/MoobiVaultImages/CustomerEngagment_icon.svg";
import IntegrationLoyalityicon from "../../assets/MoobiVaultImages/IntegrationLoyality_icon.svg";
import MultipleCurerrenciesicon from "../../assets/MoobiVaultImages/MultipleCurerrencies_icon.svg";

function DigitalWallet() {
  let DigitalObject1 = [
    {
      Title: "Client Application ",
      Icon: ClientAppIconr,
    },
    {
      Title: "Merchant   ",
      SecondTilte: "Application",
      Icon: MerchantAppicon,
    },
    {
      Title: "Agent Application",
      Icon: AgentAppicon,
    },
  ];

  let DigitalObjectMobile1 = [
    {
      Title: "Client Application ",
      Icon: ClientAppIconr,
    },
    {
      Title: "Merchant   ",
      SecondTilte: "Application",
      Icon: MerchantAppicon,
    },
  ];

  let DigitalObjectMobile2 = [
    {
      Title: "Agent Application",
      Icon: AgentAppicon,
    },
    {
      Title: "Faster  ",
      SecondTilte: "Transactions",

      Icon: fasterTransicon,
    },
  ];

  let DigitalObjectMobile3 = [
    {
      Title: "Increased Customer  ",
      SecondTilte: "Engagement",
      Icon: CustomerEngagmenticon,
    },
    {
      Title: "Streamlined   ",
      SecondTilte: " Payment Processes ",
      Icon: PaymentProcessicon,
    },
  ];

  let DigitalObjectMobile4 = [
    {
      Title: "Reduced Reliance on  ",
      SecondTilte: "Physical Cards",
      Icon: PhysicalCardsicon,
    },
    {
      Title: "Support for Multiple   ",
      SecondTilte: "Currencies",
      Icon: MultipleCurerrenciesicon,
    },
  ];

  let DigitalObjectMobile5 = [
    {
      Title: "Integration with ",
      SecondTilte: "Loyalty Programs ",
      Icon: IntegrationLoyalityicon,
    },
  ];

  let DigitalObject2 = [
    {
      Title: "Faster Transactions ",
      Icon: fasterTransicon,
    },
    {
      Title: "Increased Customer  ",
      SecondTilte: "Engagement",
      Icon: CustomerEngagmenticon,
    },
    {
      Title: "Streamlined Payment  ",
      SecondTilte: "Processes",
      Icon: PaymentProcessicon,
    },
  ];

  let DigitalObject3 = [
    {
      Title: "Reduced Reliance on  ",
      SecondTilte: "Physical Cards",
      Icon: PhysicalCardsicon,
    },
    {
      Title: "Support for Multiple   ",
      SecondTilte: "Currencies",
      Icon: MultipleCurerrenciesicon,
    },
    {
      Title: "Integration with ",
      SecondTilte: "Loyalty Programs ",
      Icon: IntegrationLoyalityicon,
    },
  ];
  return (
    <div className={classes.digitalContainer}>
      <div className={classes.allBusinessDigital}>
        <div className={classes.digitalWrapper}>
          {DigitalObject1 &&
            DigitalObject1.map((item, index) => {
              return (
                <div>
                  <div key={`Digital-${index}`} className={classes.digitalimageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="Digital Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.digitalWrapperMobile}>
          {DigitalObjectMobile1 &&
            DigitalObjectMobile1.map((item, index) => {
              return (
                <div>
                  <div key={`Digital-${index}`} className={classes.digitalimageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="Digital Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>

        <div className={classes.digitalWrapper}>
          {DigitalObject2 &&
            DigitalObject2.map((item, index) => {
              return (
                <div>
                  <div key={`Digital-${index}`} className={classes.digitalimageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="Digital Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.digitalWrapperMobile}>
          {DigitalObjectMobile2 &&
            DigitalObjectMobile2.map((item, index) => {
              return (
                <div>
                  <div key={`Digital-${index}`} className={classes.digitalimageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="Digital Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.digitalWrapper}>
          {DigitalObject3 &&
            DigitalObject3.map((item, index) => {
              return (
                <div>
                  <div key={`Digital-${index}`} className={classes.digitalimageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="Digital Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.digitalWrapperMobile}>
          {DigitalObjectMobile3 &&
            DigitalObjectMobile3.map((item, index) => {
              return (
                <div>
                  <div key={`Digital-${index}`} className={classes.digitalimageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="Digital Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.digitalWrapperMobile}>
          {DigitalObjectMobile4 &&
            DigitalObjectMobile4.map((item, index) => {
              return (
                <div>
                  <div key={`Digital-${index}`} className={classes.digitalimageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="Digital Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.digitalWrapperMobile1}>
          {DigitalObjectMobile5 &&
            DigitalObjectMobile5.map((item, index) => {
              return (
                <div>
                  <div key={`Digital-${index}`} className={classes.digitalimageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="Digital Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
      </div>
      <div className={classes.titleCircleDigital}>
        <div className={classes.digitalContainerTitles}>
          <span className={classes.digitalTitle}>
            Digital
            <span className={classes.digitalTitleTitleSecond}> Wallet</span>
          </span>
          <p className={classes.digitalTitleParagraph}>
            Empower your customers with our secure and user-friendly digital
            wallet solutions. Our development services offer seamless
            transactions, robust security, and enhanced customer engagement to
            drive growth in the digital economy.
          </p>
        </div>
        {/* <div className={classes.digitalBigCircel}>
          <img src={Digitalimage} alt="" className={classes.imageStyle} />
        </div> */}
      </div>
    </div>
  );
}

export default DigitalWallet;
