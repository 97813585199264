import React, { useEffect, useRef, useState } from "react";
import CountUp from 'react-countup';
import classes from "./AboutUs.module.scss";
import explosion from "../../assets/AboutUsImages/explosion.svg";
import financemode from "../../assets/AboutUsImages/finance_mode.svg";
import groupadd from "../../assets/AboutUsImages/group_add.svg";
import groupsearch from "../../assets/AboutUsImages/group_search.svg";
import settingsinputantenna from "../../assets/AboutUsImages/settings_input_antenna.svg";
import travelexplore from "../../assets/AboutUsImages/travel_explore.svg";

function YearsCompany() {
    const [startCounting, setStartCounting] = useState(false);
    const yearsCompanyRef = useRef(null);

    const yearsCompanyObject = [
        {
            Title: "Years of Domain Experience",
            Icon: explosion,
            number: 50,
            suffix: '+'
        },
        {
            Title: "Access to Mobile Subscribers",
            Icon: groupadd,
            number: 200,
            suffix: 'M+'
        },
        {
            Title: "Interconnected Mobile Operators",
            Icon: settingsinputantenna,
            number: 20,
            suffix: '+'
        },
    ];

    const yearsCompanyObject1 = [
        {
            Title: "Countries of Presence",
            Icon: travelexplore,
            number: 15,
            suffix: '+'
        },
        {
            Title: "Active Mobile Users",
            Icon: groupsearch,
            number: 15,
            suffix: 'M+'
        },
        {
            Title: "3-Year Revenue CAGR",
            Icon: financemode,
            number: 12,
            suffix: '%'
        },
    ];

    const yearsCompanyObjectMobile1 = [
        {
            Title: "Years of Domain ",
            TitleSecond: "Experience",
            Icon: explosion,
            number: 50,
            suffix: '+'
        },
        {
            Title: "Access to Mobile ",
            TitleSecond: "Subscribers",
            Icon: groupadd,
            number: 200,
            suffix: 'M+'
        },
    ];

    const yearsCompanyObjectMobile2 = [
        {
            Title: "Interconnected Mobile ",
            TitleSecond: "Operators",
            Icon: settingsinputantenna,
            number: 20,
            suffix: '+'
        },
        {
            Title: "Countries of ",
            TitleSecond: "Presence",
            Icon: travelexplore,
            number: 15,
            suffix: '+'
        },
    ];

    const yearsCompanyObjectMobile3 = [
        {
            Title: "Active Mobile ",
            TitleSecond: "Users",
            Icon: groupsearch,
            number: 15,
            suffix: 'M+'
        },
        {
            Title: "3-Year Revenue ",
            TitleSecond: "CAGR",
            Icon: financemode,
            number: 12,
            suffix: '%'
        },
    ];

    useEffect(() => {
        const currentRef = yearsCompanyRef.current; 

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                console.log("Component is in view");
                setStartCounting(true);
                observer.disconnect();
            }
        });

        if (currentRef) {
            observer.observe(currentRef);
            console.log("Observer is set");
        }

        return () => {
            if (currentRef) { 
                observer.unobserve(currentRef);
            }
        };
    }, []);

    return (
        <div ref={yearsCompanyRef} className={classes.YearsCompanyContainer}>
            <div className={classes.innerContainer}>
                {yearsCompanyObject.map((item, index) => (
                    <div key={index} className={classes.yearsCompanyItem}>
                        <div className={classes.outerCircle}>
                            <img src={item.Icon} alt={item.Title} className={classes.imageInsideCircle} />
                        </div>
                        <span className={classes.numberStyle}>
                            {startCounting && (
                                <CountUp
                                    end={item.number}
                                    duration={5}
                                    suffix={item.suffix}
                                    separator=","
                                />
                            )}
                        </span>
                        <span className={classes.titleYearsStyle}>{item.Title}</span>
                    </div>
                ))}
            </div>
            <div className={classes.innerContainer}>
                {yearsCompanyObject1.map((item, index) => (
                    <div key={index} className={classes.yearsCompanyItem}>
                        <div className={classes.outerCircle}>
                            <img src={item.Icon} alt={item.Title} className={classes.imageInsideCircle} />
                        </div>
                        <span className={classes.numberStyle}>
                            {startCounting && (
                                <CountUp
                                    end={item.number}
                                    duration={3}
                                    suffix={item.suffix}
                                    separator=","
                                />
                            )}
                        </span>
                        <span className={classes.titleYearsStyle}>{item.Title}</span>
                    </div>
                ))}
            </div>
            <div className={classes.innerContainerMobile}>
                {yearsCompanyObjectMobile1.map((item, index) => (
                    <div key={index} className={classes.yearsCompanyItem}>
                        <div className={classes.outerCircle}>
                            <img src={item.Icon} alt={item.Title} className={classes.imageInsideCircle} />
                        </div>
                        <span className={classes.numberStyle}>
                            {startCounting && (
                                <CountUp
                                    end={item.number}
                                    duration={3}
                                    suffix={item.suffix}
                                    separator=","
                                />
                            )}
                        </span>
                        <span className={classes.titleYearsStyle}>{item.Title}</span>
                        <span className={classes.titleYearsStyle}>{item.TitleSecond}</span>
                    </div>
                ))}
            </div>
            <div className={classes.innerContainerMobile}>
                {yearsCompanyObjectMobile2.map((item, index) => (
                    <div key={index} className={classes.yearsCompanyItem}>
                        <div className={classes.outerCircle}>
                            <img src={item.Icon} alt={item.Title} className={classes.imageInsideCircle} />
                        </div>
                        <span className={classes.numberStyle}>
                            {startCounting && (
                                <CountUp
                                    end={item.number}
                                    duration={5}
                                    suffix={item.suffix}
                                    separator=","
                                />
                            )}
                        </span>
                        <span className={classes.titleYearsStyle}>{item.Title}</span>
                        <span className={classes.titleYearsStyle}>{item.TitleSecond}</span>
                    </div>
                ))}
            </div>
            <div className={classes.innerContainerMobile}>
                {yearsCompanyObjectMobile3.map((item, index) => (
                    <div key={index} className={classes.yearsCompanyItem}>
                        <div className={classes.outerCircle}>
                            <img src={item.Icon} alt={item.Title} className={classes.imageInsideCircle} />
                        </div>
                        <span className={classes.numberStyle}>
                            {startCounting && (
                                <CountUp
                                    end={item.number}
                                    duration={5}
                                    suffix={item.suffix}
                                    separator=","
                                />
                            )}
                        </span>
                        <span className={classes.titleYearsStyle}>{item.Title}</span>
                        <span className={classes.titleYearsStyle}>{item.TitleSecond}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default YearsCompany;
