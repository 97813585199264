import React from "react";
import classes from "./MoobiDev.module.scss";
import DevOpsicon from "../../assets/MoobiDevImages/DevOps_icon.svg";
import MobilAppicon from "../../assets/MoobiDevImages/MobilApp_icon.svg";
import Assuranceicon from "../../assets/MoobiDevImages/Assurance_icon.svg";
import ItConsultingicon from "../../assets/MoobiDevImages/ItConsulting_icon.svg";
import CustomServiceicon from "../../assets/MoobiDevImages/CustomService_icon.svg";
import WebDevelopmenticon from "../../assets/MoobiDevImages/WebDevelopment_icon.svg";
import CloudComputingicon from "../../assets/MoobiDevImages/CloudComputing_icon.svg";

function SoftwareDevelopment() {
  let SoftwareDevObject1 = [
    {
      Title: "Custom Software ",
      SecondTilte: "Development",
      Icon: CustomServiceicon,
      Paragraph:
        "Customizability, scalability, integration with existing systems, and performance optimization.",
    },
    {
      Title: "Web Development",
      SecondTilte: "",
      Icon: WebDevelopmenticon,
      Paragraph:
        "Designing and developing responsive,scalable, and secure web applications and websites.",
    },
    {
      Title: "Mobile App ",
      SecondTilte: "Development",
      Icon: MobilAppicon,
      Paragraph:
        "Building native and cross-platform mobile applications for iOS and Android.",
    },
    {
      Title: "Cloud Computing ",
      SecondTilte: "Services",
      Icon: CloudComputingicon,
      Paragraph:
        "Offering cloud-based solutions, including cloud migration, architecture design, and DevOps services.",
    },
    {
      Title: "DevOps and Continuous ",
      SecondTilte: "Integration and Deployment",
      Icon: DevOpsicon,
      Paragraph:
        "Implementing DevOps practices to automate and streamline software development and deployment processes.",
    },
    {
      Title: "Quality Assurance ",
      SecondTilte: "and Testing",
      Icon: Assuranceicon,
      Paragraph:
        "Providing comprehensive QA services,including manual and automated testing, to ensure software quality and reliability.",
    },
    {
      Title: "IT Consulting and Strategy",
      Icon: ItConsultingicon,
      Paragraph:
        "Offering expert consulting services to help businesses align their technology trategies with their business goals.",
    },
    
  ];

  return (
    <div className={classes.allCont}>
      <div className={classes.titleNumberCont}>
        <div className={classes.circleNumber}>
          <div className={classes.insideCircle}>
            <span className={classes.numberInside}>1</span>
          </div>
        </div>
        <div>
          <span className={classes.softwareTitle}>
            Software
            <span className={classes.softwareSecondTitle}>Development</span>
          </span>
        </div>
      </div>
      <div className={classes.squaresContainer}>
        <div className={classes.softwareWrapper}>
          {SoftwareDevObject1 &&
            SoftwareDevObject1.map((item, index) => {
              return (
                <div key={index} className={classes.softwareInfoInside}>
                  <div className={classes.imageTitleCont}>
                    <img
                      src={item.Icon}
                      alt="Software Icons"
                      className={classes.SoftwareIcons}
                    />
                    <span className={classes.titleUnderIcon}>
                      {item.Title} <br />
                      {item.SecondTilte ? <span>{item.SecondTilte}</span> : ""}
                    </span>
                  </div>
                  <p className={classes.paragraphStyle}>{item.Paragraph}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default SoftwareDevelopment;
