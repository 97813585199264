import React from "react";
import OurPresence from "./OurPresence";
import classes from "./AboutUs.module.scss";
import OurValuesAboutUs from "./OurValuesAboutUs";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import PromoBanner from "../../components/PromoBanner/PromoBanner";
import AboutUSname from "../../assets/AboutUsImages/AboutUS_name.svg"
import MoobiBanners from "../../components/MoobiBanners/MoobiBanners";
import YearsCompany from "./YearsCompany";
import SliderTelecos from "../../components/SliderComponent/SliderTelecos";
import SliderPartners from "../../components/SliderComponent/SliderPartners";
function AboutUs() {
  return (
    <div>
      <Header />
      <MoobiBanners logoName={AboutUSname} />
      <div className="container">
        <div className={classes.wrapperInfo}>
          <div className={classes.InfoContainer}>
            <div className={classes.firstContainer}>
              <span className={classes.titleLeft}>
                Why<span className={classes.titleLeftSecond}>Us?</span>
              </span>
              <p className={classes.paragraphStyleLeft}>
                Since 2009, Moobitek has been providing innovative tech
                solutions to global markets. With a solid presence in Europe,
                the Middle East, and Africa, we collaborate with leading mobile
                operators, gaming companies, financial institutions, and other
                industry experts to deliver technology solutions that foster
                innovation and growth.<br />
                With a diverse product lineup including fintech services,
                gaming, payment solutions, value-added services, IT, and other
                development services, Moobitek is established as a digital
                expert.
              </p>
            </div>
            <div className={classes.secondContainer}>
              <span className={classes.titleRight}>
                Our<span className={classes.titleRightSecond}>Mission</span>
              </span>
              <p className={classes.paragraphStyleRight}>
                To deliver efficient, reliable digital experiences through
                honest partnerships and leading technology. We aim to provide
                the best for our clients and foster a society built on fair
                relations.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.ourValueBack}>
        <span className={classes.titleValues}>OUR VALUES</span>
      </div>
      <OurValuesAboutUs />
      <OurPresence />
      <YearsCompany />
      <div className={classes.partnerComponent1}>
        <div className={classes.partnerMobile1}>
        <span className={classes.ourPrtners}>PARTNERS</span>
        </div>
        <span className={classes.subtitlesPartner}>
          Our commitment to excellence ensures that our clients stay ahead in a
          rapidly evolving digital landscape.
        </span>
        <SliderPartners />
      </div>
      <div className={classes.partnerComponent}>
        <div className={classes.partnerMobile}>
        <span className={classes.ourtelcos}>TELCOS</span>
        </div>
        <SliderTelecos />
      </div>
      <PromoBanner />
      <Footer />
    </div>
  );
}

export default AboutUs;
