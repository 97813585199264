import React from "react";
import classes from "./MoobiDev.module.scss";

function CompanyTechnology({
  firstTitle,
  secondTitle,
  number,
  paragraph,
  image,
  direction,
  titleParagraphRight,
  circleTitleReverse,
}) {
  return (
    <div className="container">
    <div
      className={`${classes.companyTechnologyComponent} ${classes[direction]}`}
    >
      <div className={classes.companyTechnologyContainer}>
        <div
          className={`${classes.companyTechnologyTittleNumber} ${classes[circleTitleReverse]}`}
        >
          <div className={classes.circleNumber}>
            <div className={classes.insideCircle}>
              <span className={classes.numberInside}>{number}</span>
            </div>
          </div>
          <div>
            <span
              className={`${classes.softwareTitle} ${classes[titleParagraphRight]}`}
            >
              {firstTitle}
              <span className={classes.softwareSecondTitle}>{secondTitle}</span>
            </span>
          </div>
        </div>
        <p
          className={`${classes.companyTechnologyParagraph} ${classes[titleParagraphRight]}`}
        >
          {paragraph}
        </p>
      </div>
      <div className={classes.companyTechnologyImage}>
        <img src={image} alt="" className={classes.imageStyle} />
      </div>
    </div>
    </div>
  );
}

export default CompanyTechnology;
