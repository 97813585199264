import React from "react";
import CoreFeatures from "./CoreFeatures";
import PaymentProcess from "./PaymentProcess";
import PaymentMethods from "./PaymentMethods";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SecurityCompliance from "./SecurityCompliance";
import PromoBanner from "../../components/PromoBanner/PromoBanner";
import MoobiBanners from "../../components/MoobiBanners/MoobiBanners";
import MobiPayLogo from "../../assets/MoobiPayImages/MoobiPay_logo.svg";
import MobiPayName from "../../assets/MoobiPayImages/MoobiPayName_logo.svg";
import OverViewComponent from "../../components/OverViewComponent/OverViewComponent";
import OverVeiwMobiPayicon from "../../assets/MoobiPayImages/OverVeiwMobiPay_icon.png";

function MoobiPay() {
  return (
    <>
      <Header />
      <MoobiBanners logoName={MobiPayName} LogoIcon={MobiPayLogo} />
      <OverViewComponent
        paragraph="Moobitek provides a secure and versatile payment gateway that simplifies transactions for businesses of all sizes. Our platform supports consumer payments, easy refunds, and efficient disbursements, handling multiple currencies and payment methods seamlessly. Whether online, in-store, or on mobile, we offer real-time analytics, fraud protection, and smooth integration to enhance your payment experience and boost customer satisfaction."
        image={OverVeiwMobiPayicon}
      />
      <div className="container">
      <CoreFeatures />
      <SecurityCompliance />
      </div>

      <PaymentProcess />
      <PaymentMethods />

      <PromoBanner />
      <Footer />
    </>
  );
}

export default MoobiPay;
