import React from "react";
import classes from "./MoobiPay.module.scss";
import Dssicon from "../../assets/MoobiPayImages/Dss_icon.svg";
import desputeicon from "../../assets/MoobiPayImages/despute_icon.svg";
import Preventionicon from "../../assets/MoobiPayImages/Prevention_icon.svg";
import DataEncryptionicon from "../../assets/MoobiPayImages/DataEncryption_icon.svg";

function SecurityCompliance() {
  let SecurityCompliance = [
    {
      Title: "PCI-DSS Compliance",
      Icon: Dssicon,
    },
    {
      Title: "Fraud Prevention",
      Icon: Preventionicon,
    },
    {
      Title: "Data Encryption ",
      Icon: DataEncryptionicon,
    },
    {
      Title: "Dispute Resolution",
      Icon: desputeicon,
    },
  ];

  let SecurityComplianceMobile1 = [
    {
      Title: "PCI-DSS Compliance",
      Icon: Dssicon,
    },
    {
      Title: "Fraud Prevention",
      Icon: Preventionicon,
    },
  ];

  let SecurityComplianceMobile2 = [
    {
      Title: "Data Encryption ",
      Icon: DataEncryptionicon,
    },
    {
      Title: "Dispute Resolution",
      Icon: desputeicon,
    },
  ];

  return (
    <div className={classes.allComponent}>
        <span className={classes.mainTitle}>
          Security &<span className={classes.secondMainTitle}>Compliance</span>
        </span>
      <div className={classes.coreFeaturesDetails}>
        <div className={classes.coreFeaturesWrapper}>
          {SecurityCompliance &&
            SecurityCompliance.map((item, index) => {
              return (
                <div key={index} className={classes.securityInfoInside}>
                  <div className={classes.coreFeaturesCircle}>
                    <img
                      src={item.Icon}
                      alt="features Icons"
                      className={classes.coreFeaturesIcons}
                    />
                  </div>
                  <span className={classes.featurestTitleUnderIcon}>
                    {item.Title}
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.coreFeaturesWrapperMobile1}>
          {SecurityComplianceMobile1 &&
            SecurityComplianceMobile1.map((item, index) => {
              return (
                <div key={index} className={classes.securityInfoInside}>
                  <div className={classes.coreFeaturesCircle}>
                    <img
                      src={item.Icon}
                      alt="features Icons"
                      className={classes.coreFeaturesIcons}
                    />
                  </div>
                  <span className={classes.featurestTitleUnderIcon}>
                    {item.Title}
                  </span>
                </div>
              );
            })}
        </div>
        <div className={classes.coreFeaturesWrapperMobile1}>
          {SecurityComplianceMobile2 &&
            SecurityComplianceMobile2.map((item, index) => {
              return (
                <div key={index} className={classes.securityInfoInside}>
                  <div className={classes.coreFeaturesCircle}>
                    <img
                      src={item.Icon}
                      alt="features Icons"
                      className={classes.coreFeaturesIcons}
                    />
                  </div>
                  <span className={classes.featurestTitleUnderIcon}>
                    {item.Title}
                  </span>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default SecurityCompliance;
