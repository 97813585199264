import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classes from "./Slider.module.scss";
import Orangelogo from "../../assets/CompanyLogos/Orange_logo.svg";
import Safaricomlogo from "../../assets/CompanyLogos/Safaricom_logo.svg";
import MTNlogo from "../../assets/CompanyLogos/MTN_logo.svg";
import Vodacomlogo from "../../assets/CompanyLogos/Vodacom_logo.svg";
import Africelllogo from "../../assets/CompanyLogos/Africell_logo.svg";
import GLOlogo from "../../assets/CompanyLogos/GLO_logo.svg";
import Airtellogo from "../../assets/CompanyLogos/Airtel_logo.svg";
import TIGOlogo from "../../assets/CompanyLogos/TIGO_logo.svg";
import Halotellogo from "../../assets/CompanyLogos/Halotel_logo.svg";

const settings = {
  rows: 1,
  dots: false,
  arrows: true,
  infinite: true,
  speed: 300,
  slidesToShow: 5,
  slidesToScroll: 5,
  mobileFirst: true,
  autoplay: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        rows: 1,
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        dots: false,
      },
    },
    {
      breakpoint: 1199,
      settings: {
        rows: 1,
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: false,
      },
    },
  ],
};

function SliderTelecos() {
  return (
    <div className={`container ${classes.sliderContainer} white-arrows`}>
      <Slider {...settings} className={classes.sliderCont}>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={Safaricomlogo}
              alt="Safaricomlogo"
              className={classes.logoSafaricom}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={Vodacomlogo}
              alt="Vodacomlogo"
              className={classes.logoVodacom}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={Africelllogo}
              alt="Africelllogo"
              className={classes.africellLogoIcon}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainer}>
            <img
              src={Airtellogo}
              alt="Airtellogo"
              className={classes.airtelLogoIcon}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainerHalote}>
            <img
              src={Halotellogo}
              alt="Halotellogo"
              className={classes.logoHalote}
            />
          </div>
        </div>
        <div>
          <div className={classes.logoContainerSecond}>
            <img src={GLOlogo} alt="GLOlogo" className={classes.logoIcon} />
          </div>
        </div>

        <div>
          <div className={classes.logoContainerSecond}>
            <img src={MTNlogo} alt="MTNlogo" className={classes.logoIcon} />
          </div>
        </div>
        <div>
          <div className={classes.logoContainerSecond}>
            <img
              src={Orangelogo}
              alt="Orangelogo"
              className={classes.logoIcon}
            />
          </div>
        </div>

        <div>
          <div className={classes.logoContainerSecond}>
            <img src={TIGOlogo} alt="TIGOlogo" className={classes.logoIcon} />
          </div>
        </div>

      </Slider>
    </div>
  );
}

export default SliderTelecos;
