import React from "react";
import classes from "./AboutUs.module.scss";
import Lamp from "../../assets/AboutUsImages/Innovation_Aboutus.svg";
import ShakeHands from "../../assets/AboutUsImages/Integrity_AboutUs.svg";
import CustomerFocus from "../../assets/AboutUsImages/Customer_AboutUS.svg";
import Inclusivity from "../../assets/AboutUsImages/Inclusivity_aboutus.svg";
import LampMobile from "../../assets/AboutUsImages/InnovationaboutUs_mobile.svg";
import ShakeHandsMobile from "../../assets/AboutUsImages/IntegrityAboutsus_mobile.svg";
import CustomerFocusMobile from "../../assets/AboutUsImages/CustomerAboutus_mobile.svg";
import InclusivityMobile from "../../assets/AboutUsImages/InclusivityAboutus_mobile.svg";

function OurValuesAboutUs() {
  let ourValuesObject = [
    {
      TitleFirst: "Integrity & ",
      TitleSecond: "Transparency",
      Icon: ShakeHands,
      IconMobile: ShakeHandsMobile,
      Paragraph: "Upholding honesty and clear communication.",
    },
    {
      TitleFirst: "Innovation &",
      TitleSecond: "Excellence",
      Icon: Lamp,
      IconMobile: LampMobile,
      Paragraph:
        "Continuously developing and delivering high-quality solutions.",
    },
    {
      TitleFirst: "Customer Focus &",
      TitleSecond: "Collaboration",
      Icon: CustomerFocus,
      IconMobile: CustomerFocusMobile,
      Paragraph: "Prioritizing clients’ needs and valuing teamwork.",
    },
    {
      TitleFirst: "Inclusivity &",
      TitleSecond: "Sustainability",
      Icon: Inclusivity,
      IconMobile: InclusivityMobile,
      Paragraph: "Embracing diversity and making a positive impact on society.",
    },
  ];
  return (
    <div className={classes.ourValuesContainer}>
      <div className="container">
      <div className={classes.valuesWrapper}>
        {ourValuesObject &&
          ourValuesObject.map((item, index) => {
            return (
              <div key={index} className={classes.imageTitleCont}>
                <div className={classes.circlesItem}>
                  <img
                    src={item.Icon}
                    alt="ourValues Logos"
                    className={`${classes.iconInside} ${classes.desktopImage}`}
                  />
                  <img
                    src={item.IconMobile}
                    alt="ourValues Logos"
                    className={`${classes.iconInside} ${classes.mobileImage}`}
                  />
                </div>
                <span className={classes.firstTitleValue}>
                  {item.TitleFirst} <br />
                  <span className={classes.secondTitleValue}>
                    {item.TitleSecond}
                  </span>
                </span>
                <p className={classes.paragraphStyle}>{item.Paragraph}</p>
              </div>
            );
          })}
      </div>
      </div>
    </div>
  );
}

export default OurValuesAboutUs;
