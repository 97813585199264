import React from "react";
import classes from "./ContactUs.module.scss";

function OurOffices() {
  const salesEmail = "sales@moobitek.com";
  return (
    <div className={classes.mapTitlesContainer}>
      <div className={classes.mapSquare}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.61777520124!2d35.50105248423031!3d33.8994965311694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17fd715be7db%3A0x7a033d4cef3a1529!2sPalladium!5e0!3m2!1sen!2slb!4v1727877896753!5m2!1sen!2slb"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Map showing location of Palladium"
        ></iframe>
      </div>
      <div>
        <span className={classes.titleMap}>Main office.</span>
        <span className={classes.subTitleLocation}>Lebanon </span>

        <span className={classes.subTitleMap}>Address</span>
        <span className={classes.underSubTitleMap}>
          Beirut, Bab Idris, Palladium <br />
          Bldg.
        </span>
        <span className={classes.subTitleMap}>Contact</span>
        <span className={classes.underSubTitleMap}>
          T:00961 81 684 684 <br />
          <a href={`mailto:${salesEmail}`} className={classes.emailoffice}>
            {salesEmail}
          </a>
        </span>

        <span className={classes.titleMap}>Other Locations</span>
        <span className={classes.subTitleLocation}>UAE </span>
        <span className={classes.subTitleMap}>Address</span>
        <span className={classes.underSubTitleMap}>
          Sharjah Media City, Sharjah, <br />
          UAE.
        </span>
        <span className={classes.subTitleLocation}>Ghana</span>
        <span className={classes.subTitleMap}>Address</span>
        <span className={classes.underSubTitleMap}>
          6 Suya Street, East Legon <br />
          Accra, Ghana.
        </span>

      </div>
    </div>
  );
}

export default OurOffices;
