import React from "react";
import classes from "./MoobiVault.module.scss";
import EkycIcon from "../../assets/MoobiVaultImages/Ekyc_Icon.svg";
import Dataicon from "../../assets/MoobiVaultImages/Data_icon.svg";
import Crediticon from "../../assets/MoobiVaultImages/Credit_icon.svg";
import Paymentlogo from "../../assets/MoobiVaultImages/Payment_logo.svg";
import Productlogo from "../../assets/MoobiVaultImages/Product_logo.svg";
import Digitalicon from "../../assets/MoobiVaultImages/Digital_icon.svg";
import Businesslogo from "../../assets/MoobiVaultImages/Business_logo.svg";
import Electricicon from "../../assets/MoobiVaultImages/Electric_icon.svg";
import Automatedicon from "../../assets/MoobiVaultImages/Automated_icon.svg";
import MerchantLogo from "../../assets/MoobiVaultImages/Merchant_logosvg.svg";
import Collectionlogo from "../../assets/MoobiVaultImages/Collection_logo.svg";
import Integreatericon from "../../assets/MoobiVaultImages/Integreater_icon.svg";
import OmniChannelicon from "../../assets/MoobiVaultImages/OmniChannel_icon.svg";
import UserManagmenticon from "../../assets/MoobiVaultImages/UserManagment_icon.svg";

import LMSCenterimage from "../../assets/MoobiVaultImages/LMSCenter_image.svg";

function LoanManagment() {
  let loanManagment1 = [
    {
      Title: "eKYC",
      Icon: EkycIcon,
    },
    {
      Title: "User ",
      SecondTilte: "Management",
      Icon: UserManagmenticon,
    },
  ];

  let loanManagment2 = [
    {
      Title: "Automated Loan Origination",
      Icon: Automatedicon,
    },
    {
      Title: "Omni Channel ",
      SecondTilte: "Leads Generation",
      Icon: OmniChannelicon,
    },
  ];

  let loanManagment3 = [
    {
      Title: "Digital ",
      SecondTilte: "Onboarding",
      Icon: Digitalicon,
    },
    {
      Title: "Data Protection",
      Icon: Dataicon,
    },
  ];

  let loanManagment4 = [
    {
      Title: "Credit Scoring & ",
      SecondTilte: "Decision Engine",
      Icon: Crediticon,
    },
    {
      Title: "Integration with credit bureau,",
      SecondTilte: "Banks and e-wallets",
      Icon: Integreatericon,
    },
  ];

  let loanManagment5 = [
    {
      Title: "Electronic Contract Signature",
      Icon: Electricicon,
    },
  ];

  let ModulesManagmentObject = [
    {
      Title: "Merchant ",
      SecondTilte: "Module",
      Icon: MerchantLogo,
    },
    {
      Title: "Product",
      SecondTilte: "Module",
      Icon: Productlogo,
    },
    {
      Title: "Collection ",
      SecondTilte: "Module",
      Icon: Collectionlogo,
    },
    {
      Title: "Payment",
      SecondTilte: "Module",
      Icon: Paymentlogo,
    },
    {
      Title: "Business",
      SecondTilte: "Intelligence",
      Icon: Businesslogo,
    },
  ];

  return (
    <>
    <div className="container">
      <div className={classes.LoanManagmentContainer}>
        <div className={classes.titleParagraph}>
          <span className={classes.LoanManagmentTitle}>
            Loan Management
            <span className={classes.LoanManagmentTitleSecond}> System</span>
          </span>
          <p className={classes.LoanManagmentParagraph}>
            Streamline your lending process with our advanced fintech loan
            management system, featuring automation and smart credit decisioning
            to optimize efficiency, enhance security, and deliver a seamless
            experience for both lenders and borrowers.
          </p>
        </div>
      </div>
      </div>
      <div className={classes.loadManagmentSystem}>
        <div className={classes.LoanCenterCircle}>
          <img
            src={LMSCenterimage}
            alt="LMSCenterimage"
            className={classes.loanCenterImage}
          />
        </div>
        <div className={classes.managmentWrapper}>
          {loanManagment1 &&
            loanManagment1.map((item, index) => {
              return (
                <div>
                  <div
                    key={`loanManagment-${index}`}
                    className={classes.imageTitleCont}
                  >
                    <img
                      src={item.Icon}
                      alt="Loand Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    {item.SecondTilte ? <span>{item.SecondTilte}</span> : ""}
                  </span>
                  <div
                    className={`${classes.arrowStyle} ${classes.arrowLeft1}`}
                  />
                  <div
                    className={`${classes.arrowStyle} ${classes.arrowRight1}`}
                  />
                </div>
              );
            })}
        </div>

        <div className={classes.managmentWrapper1}>
          {loanManagment2 &&
            loanManagment2.map((item, index) => {
              return (
                <div>
                  <div
                    key={`loanManagment-${index}`}
                    className={classes.imageTitleCont}
                  >
                    <img
                      src={item.Icon}
                      alt="Loand Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    {item.SecondTilte ? <span>{item.SecondTilte}</span> : ""}
                  </span>
                  <div
                    className={`${classes.arrowStyle} ${classes.arrowLeft2}`}
                  />
                  <div
                    className={`${classes.arrowStyle} ${classes.arrowRight2}`}
                  />
                </div>
              );
            })}
        </div>
        <div className={classes.managmentWrapper2}>
          {loanManagment3 &&
            loanManagment3.map((item, index) => {
              return (
                <div>
                  <div
                    key={`loanManagment-${index}`}
                    className={classes.imageTitleCont}
                  >
                    <img
                      src={item.Icon}
                      alt="Loand Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    {item.SecondTilte ? <span>{item.SecondTilte}</span> : ""}
                  </span>
                  <div
                    className={`${classes.arrowStyle} ${classes.arrowLeft3}`}
                  />
                  <div
                    className={`${classes.arrowStyle} ${classes.arrowRight3}`}
                  />
                </div>
              );
            })}
        </div>
        <div className={classes.managmentWrapper3}>
          {loanManagment4 &&
            loanManagment4.map((item, index) => {
              return (
                <div>
                  <div
                    key={`loanManagment-${index}`}
                    className={classes.imageTitleCont}
                  >
                    <img
                      src={item.Icon}
                      alt="Loand Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    {item.SecondTilte ? <span>{item.SecondTilte}</span> : ""}
                  </span>
                  <div
                    className={`${classes.arrowStyle} ${classes.arrowLeft4}`}
                  />
                  <div
                    className={`${classes.arrowStyle} ${classes.arrowRight4}`}
                  />
                </div>
              );
            })}
        </div>
        <div className={classes.managmentWrapper4}>
          {loanManagment5 &&
            loanManagment5.map((item, index) => {
              return (
                <div>
                  <div
                    key={`loanManagment-${index}`}
                    className={classes.imageTitleCont}
                  >
                    <img
                      src={item.Icon}
                      alt="Loand Icons"
                      className={classes.LoanIcons}
                    />
                  </div>
                  <span className={classes.titleUnderIcon}>
                    {item.Title} <br />
                    {item.SecondTilte ? <span>{item.SecondTilte}</span> : ""}
                  </span>
                  <div
                    className={`${classes.arrowStyle} ${classes.arrowCenter}`}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className="container">
      <div className={classes.moduleContainer}>
        <div className={classes.ModulesWrapper}>
          {ModulesManagmentObject &&
            ModulesManagmentObject.map((item, index) => {
              return (
                <div>
                  <div
                    key={`loanManagment-${index}`}
                    className={classes.squareTitleCont}
                  >
                    <img
                      src={item.Icon}
                      alt="Loand Icons"
                      className={classes.ModuleIcons}
                    />
                  </div>
                  <span className={classes.ModuletitleUnderIcon}>
                    {item.Title} <br />
                    <span>{item.SecondTilte}</span>
                  </span>
                </div>
              );
            })}
        </div>
      </div>
      </div>
    </>
  );
}

export default LoanManagment;
