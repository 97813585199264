import React from "react";
import classes from "./MoobiDev.module.scss";
import ProductManager from "./ProductManager";
import UsedTechnology from "./UsedTechnology";
import CompanyTechnology from "./CompanyTechnology";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import SoftwareDevelopment from "./SoftwareDevelopment";
import MoobiBanners from "../../components/MoobiBanners/MoobiBanners";
import MobiDevLogo from "../../assets/MoobiDevImages/MoobiDev_logo.svg";
import MobiDevName from "../../assets/MoobiDevImages/MoobiDevName_logo.svg";
import OverVeiwComponent from "../../components/OverViewComponent/OverViewComponent";
import OverVeiwMobiDevicon from "../../assets/MoobiDevImages/OverVeiwMobiDev_icon.png";
import TechnologyFirsticon from "../../assets/MoobiDevImages/TechnologyFirst_icon.png";
import TechnologySecondicon from "../../assets/MoobiDevImages/TechnologySecond_icon.png";

import PromoBanner from "../../components/PromoBanner/PromoBanner";

function MoobiDev() {
  return (
    <>
      <Header />
      <MoobiBanners logoName={MobiDevName} LogoIcon={MobiDevLogo} />
      <OverVeiwComponent
        paragraph="At Moobitek we create innovative software solutions that drive digital transformation and deliver real business value. Our team of experienced designers and skilled developers uses cutting-edge technologies and agile methodologies to build high-quality, scalable, and secure software tailored to your needs."
        image={OverVeiwMobiDevicon}
      />
      <div className="container">
      <div className={classes.titleContainer}>
        <span className={classes.MoobiDevTitle}>
          Ser<span className={classes.MoobiDevSecondTitle}>vices</span>
        </span>
      </div>
      <SoftwareDevelopment />
      </div>
      <CompanyTechnology
        firstTitle="Product"
        secondTitle="Management"
        number="2"
        paragraph="Product Management at Moobitek focuses on developing innovative solutions that meet customer needs and drive growth. By combining market research, agile methodologies, and data-driven insights, our team ensures each product aligns with our strategic goals and provides exceptional user experiences."
        image={TechnologyFirsticon}
        direction="reverseDirection"
        titleParagraphRight="titleParagraphRight"
        circleTitleReverse="circleTitleReverse"
      />
      <ProductManager />
      <CompanyTechnology
        firstTitle="Used"
        secondTitle="Technologies"
        number="3"
        paragraph="At Moobitek we believe that the right technology can turn ideas into reality. Our team of experts leverages a diverse range of modern technologies to build innovative, scalable, and secure solutions. we have the tools and knowledge to meet your needs."
        image={TechnologySecondicon}
        titleParagraphRight="titleParagraphLeft"

      />
      <div className="container">
      <UsedTechnology />
      </div>
      <PromoBanner />
      <Footer />
    </>
  );
}

export default MoobiDev;
