import React from "react";
import classes from "./MoobiDev.module.scss";
import Elogo from "../../assets/MoobiDevImages/E_logo.svg";
import Xdlogo from "../../assets/MoobiDevImages/Xd_logo.svg";
import AWSLogo from "../../assets/MoobiDevImages/AWS_Logo.svg";
import Phplogo from "../../assets/MoobiDevImages/Php_logo.svg";
import Soaplogo from "../../assets/MoobiDevImages/Soap_logo.svg";
import kalilogo from "../../assets/MoobiDevImages/kali_logo.svg";
import javalogo from "../../assets/MoobiDevImages/java_logo.svg";
import CPluslogo from "../../assets/MoobiDevImages/C++_logo.svg";
import Mysqllogo from "../../assets/MoobiDevImages/Mysql_logo.svg";
import reactlogo from "../../assets/MoobiDevImages/react_logo.svg";
import Figmalogo from "../../assets/MoobiDevImages/Figma_logo.svg";
import DockerLogo from "../../assets/MoobiDevImages/Docker_Logo.svg";
import DjangoLogo from "../../assets/MoobiDevImages/Django_Logo.svg";
import OracleLogo from "../../assets/MoobiDevImages/Oracle_Logo.svg";
import GitLablogo from "../../assets/MoobiDevImages/GitLab_logo.svg";
import Jenkinslogo from "../../assets/MoobiDevImages/Jenkins_logo.svg";
import laravellogo from "../../assets/MoobiDevImages/laravel_logo.svg";
import Angularlogo from "../../assets/MoobiDevImages/Angular_logo.svg";
import freegitlogo from "../../assets/MoobiDevImages/freegit_logo.svg";
import flutterlogo from "../../assets/MoobiDevImages/flutter_logo.svg";
import RestApilogo from "../../assets/MoobiDevImages/RestApi_logo.svg";
import swaggerlogo from "../../assets/MoobiDevImages/swagger_logo.svg";
import Postmanlogo from "../../assets/MoobiDevImages/Postman_logo.svg";
import SeleniumLogo from "../../assets/MoobiDevImages/Selenium_Logo.svg";
import OVHcloudlogo from "../../assets/MoobiDevImages/OVHcloud_logo.svg";
import KubernetesLogo from "../../assets/MoobiDevImages/Kubernetes_Logo.svg";
import javascriptlogo from "../../assets/MoobiDevImages/javascript_logo.svg";
import ReactNativelogo from "../../assets/MoobiDevImages/ReactNative_logo.svg";
import jmetersquarelogo from "../../assets/MoobiDevImages/jmetersquare_logo.svg";
import MicrosoftAzureLogo from "../../assets/MoobiDevImages/MicrosoftAzure_Logo.svg";

function UsedTechnology() {
  let ProgrammingLanguagesObject = [
    {
      Icon: Phplogo,
    },
    {
      Icon: javalogo,
    },
    {
      Icon: laravellogo,
    },
    {
      Icon: javascriptlogo,
    },
    {
      Icon: CPluslogo,
    },
  ];

  let FrameworksandLibrariesObject = [
    {
      Icon: reactlogo,
    },
    {
      Icon: Angularlogo,
    },
    {
      Icon: DjangoLogo,
    },
  ];

  let UIUXDDesignObject = [
    {
      Icon: Figmalogo,
    },
    {
      Icon: Xdlogo,
    },
  ];

  let DevelopmentToolsObject = [
    {
      Icon: freegitlogo,
    },
    {
      Icon: DockerLogo,
    },
    {
      Icon: KubernetesLogo,
    },
    {
      Icon: Jenkinslogo,
    },
  ];

  let DatabasesObject = [
    {
      Icon: Mysqllogo,
    },
    {
      Icon: OracleLogo,
    },
  ];

  let CloudPlatformandServicesObject = [
    {
      Icon: AWSLogo,
    },
    {
      Icon: MicrosoftAzureLogo,
    },
    {
      Icon: OVHcloudlogo,
    },
  ];

  let DevOpsObject = [
    {
      Icon: Jenkinslogo,
    },
    {
      Icon: GitLablogo,
    },
  ];

  let CybersecurityObject = [
    {
      Icon: kalilogo,
    },
  ];

  let MobileTechnologiesObject = [
    {
      Icon: flutterlogo,
    },
    {
      Icon: ReactNativelogo,
    },
  ];

  let APIDevelopmentObject = [
    {
      Icon: RestApilogo,
    },
    {
      Icon: Soaplogo,
    },
    {
      Icon: swaggerlogo,
    },
  ];

  let QualityAssurancObject = [
    {
      Icon: Postmanlogo,
    },
    {
      Icon: jmetersquarelogo,
    },
    {
      Icon: swaggerlogo,
    },
    {
      Icon: SeleniumLogo,
    },
    {
      Icon: Elogo,
    },
  ];

  let ProgrammingLanguagesObjectMobile1 = [
    {
      Icon: Phplogo,
    },
    {
      Icon: javalogo,
    },
    {
      Icon: laravellogo,
    },
  ];
  let ProgrammingLanguagesObjectMobile2 = [
    {
      Icon: javascriptlogo,
    },
    {
      Icon: CPluslogo,
    },
  ];
  let DevelopmentToolsObjectMobile1 = [
    {
      Icon: freegitlogo,
    },
    {
      Icon: DockerLogo,
    },
    {
      Icon: KubernetesLogo,
    },
  ];
  let DevelopmentToolsObjectMobile2 = [
    {
      Icon: Jenkinslogo,
    },
  ];

  let QualityAssurancObjectMobile1 = [
    {
      Icon: Postmanlogo,
    },
    {
      Icon: jmetersquarelogo,
    },
    {
      Icon: swaggerlogo,
    },
  ];

  let QualityAssurancObjectMobile2 = [
    {
      Icon: SeleniumLogo,
    },
    {
      Icon: Elogo,
    },
  ];

  return (
    <div className={classes.usedTechnologyContainer}>
      <div className={classes.allUsedtechnology}>
        <div className={classes.titlesContainer}>
          <span
            className={`${classes.mainTitle} ${classes.underLinePrograming}`}
          >
            Programming
            <span className={classes.mainTitleSecond}>Languages</span>
          </span>
          <span className={classes.mainSubTitle}>
            PHP, Laravel, Java,
            <span className={classes.mainSecondSubTitle}>
              JavaScript, C++, etc.
            </span>
          </span>
        </div>
        <div className={classes.usedTechnologyWrapper}>
          {ProgrammingLanguagesObject &&
            ProgrammingLanguagesObject.map((item, index) => {
              return (
                <div key={index} className={classes.usedTechnologyCircles}>
                  <div className={classes.usedTechnologyinsideCircle}>
                    <img
                      src={item.Icon}
                      alt="usedTechnology Icons"
                      className={classes.usedTechnologyIcons}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className={classes.usedTechnologyWrapperMobile}>
          {ProgrammingLanguagesObjectMobile1 &&
            ProgrammingLanguagesObjectMobile1.map((item, index) => {
              return (
                <div key={index} className={classes.usedTechnologyCircles}>
                  <div className={classes.usedTechnologyinsideCircle}>
                    <img
                      src={item.Icon}
                      alt="usedTechnology Icons"
                      className={classes.usedTechnologyIcons}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className={classes.usedTechnologyWrapperMobileUnder}>
          {ProgrammingLanguagesObjectMobile2 &&
            ProgrammingLanguagesObjectMobile2.map((item, index) => {
              return (
                <div key={index} className={classes.usedTechnologyCircles}>
                  <div className={classes.usedTechnologyinsideCircle}>
                    <img
                      src={item.Icon}
                      alt="usedTechnology Icons"
                      className={classes.usedTechnologyIcons}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className={classes.allUsedtechnology}>
        <div className={classes.titlesBesideOthers}>
          <div className={classes.titlesContainerSecond}>
            <div className={classes.titlesContainer}>
              <span
                className={`${classes.mainTitle} ${classes.underLineFrameWork}`}
              >
                Frameworks &
                <span className={classes.mainTitleSecond}>Libraries</span>
              </span>
              <span className={classes.mainSubTitle}>
                React, Angular, Django, etc.
              </span>
            </div>
            <div className={classes.spaceCircles}>
              <div className={classes.usedTechnologyWrapper2}>
                {FrameworksandLibrariesObject &&
                  FrameworksandLibrariesObject.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classes.usedTechnologyCircles}
                      >
                        <div className={classes.usedTechnologyinsideCircle}>
                          <img
                            src={item.Icon}
                            alt="usedTechnology Icons"
                            className={classes.usedTechnologyIcons}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={classes.titlesContainerSecond}>
            <div className={classes.titlesContainer}>
              <span className={`${classes.mainTitle} ${classes.underLineUiUx}`}>
                UI/UX DESIGN
                <span className={classes.mainTitleSecond}>Tools</span>
              </span>
              <span className={classes.mainSubTitle}>
                Figma,Xd,etc.
              </span>
            </div>
            <div className={classes.spaceCircles}>
              <div className={classes.usedTechnologyWrapperDown2}>
                {UIUXDDesignObject &&
                  UIUXDDesignObject.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classes.usedTechnologyCircles}
                      >
                        <div className={classes.usedTechnologyinsideCircle}>
                          <img
                            src={item.Icon}
                            alt="usedTechnology Icons"
                            className={classes.usedTechnologyIcons}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.allUsedtechnology}>
        <div className={classes.titlesContainer}>
          <span
            className={`${classes.mainTitle} ${classes.underLineDevelopment}`}
          >
            Development
            <span className={classes.mainTitleSecond}>Tools</span>
          </span>
          <span className={classes.mainSubTitle}>
            Git, Docker, Kubernetes,
            <span className={classes.mainSecondSubTitle}>Jenkins, etc</span>
          </span>
        </div>
        <div className={classes.usedTechnologyWrapper}>
          {DevelopmentToolsObject &&
            DevelopmentToolsObject.map((item, index) => {
              return (
                <div key={index} className={classes.usedTechnologyCircles}>
                  <div className={classes.usedTechnologyinsideCircle}>
                    <img
                      src={item.Icon}
                      alt="usedTechnology Icons"
                      className={classes.usedTechnologyIcons}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className={classes.usedTechnologyWrapperMobile}>
          {DevelopmentToolsObjectMobile1 &&
            DevelopmentToolsObjectMobile1.map((item, index) => {
              return (
                <div key={index} className={classes.usedTechnologyCircles}>
                  <div className={classes.usedTechnologyinsideCircle}>
                    <img
                      src={item.Icon}
                      alt="usedTechnology Icons"
                      className={classes.usedTechnologyIcons}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className={classes.usedTechnologyWrapperMobileUnder}>
          {DevelopmentToolsObjectMobile2 &&
            DevelopmentToolsObjectMobile2.map((item, index) => {
              return (
                <div key={index} className={classes.usedTechnologyCircles}>
                  <div className={classes.usedTechnologyinsideCircle}>
                    <img
                      src={item.Icon}
                      alt="usedTechnology Icons"
                      className={classes.usedTechnologyIcons}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      <div className={classes.allUsedtechnology}>
        <div className={classes.titlesBesideOthers}>
          <div className={classes.titlesContainerSecond}>
            <div className={classes.titlesContainer}>
              <span
                className={`${classes.mainTitle} ${classes.underLineDataBase}`}
              >
                Data
                <span className={classes.mainTitleSecond}>bases</span>
              </span>
              <span className={classes.mainSubTitle}>MySQL, Oracle, etc. </span>
            </div>
            <div className={classes.spaceCircles}>
              <div className={classes.usedTechnologyWrapperDown2}>
                {DatabasesObject &&
                  DatabasesObject.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classes.usedTechnologyCircles}
                      >
                        <div className={classes.usedTechnologyinsideCircle}>
                          <img
                            src={item.Icon}
                            alt="usedTechnology Icons"
                            className={classes.usedTechnologyIcons}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={classes.titlesContainerSecond}>
            <div className={classes.titlesContainer}>
              <span
                className={`${classes.mainTitle} ${classes.underLineCloudPlattform}`}
              >
                Cloud Platform &
                <span className={classes.mainTitleSecond}>Services</span>
              </span>
              <span className={classes.mainSubTitle}>AWS, Azure, OVH</span>
            </div>
            <div className={classes.spaceCircles}>
              <div className={classes.usedTechnologyWrapper2}>
                {CloudPlatformandServicesObject &&
                  CloudPlatformandServicesObject.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classes.usedTechnologyCircles}
                      >
                        <div className={classes.usedTechnologyinsideCircle}>
                          <img
                            src={item.Icon}
                            alt="usedTechnology Icons"
                            className={classes.usedTechnologyIcons}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.allUsedtechnology}>
        <div className={classes.titlesBesideOthers}>
          <div className={classes.titlesContainerSecond}>
            <div className={classes.titlesContainer}>
              <span
                className={`${classes.mainTitle} ${classes.underLineDevOps}`}
              >
                DevOps &
                <span className={classes.mainTitleSecond}>CI/CD Tools</span>
              </span>
              <span className={classes.mainSubTitle}>
                Jenkins, GitLab CI, etc.
              </span>
            </div>
            <div className={classes.spaceCircles}>
              <div className={classes.usedTechnologyWrapperDown2}>
                {DevOpsObject &&
                  DevOpsObject.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classes.usedTechnologyCircles}
                      >
                        <div className={classes.usedTechnologyinsideCircle}>
                          <img
                            src={item.Icon}
                            alt="usedTechnology Icons"
                            className={classes.usedTechnologyIcons}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={classes.titlesContainerSecond}>
            <div className={classes.titlesContainer}>
              <span
                className={`${classes.mainTitle} ${classes.underLineMobile}`}
              >
                Mobile
                <span className={classes.mainTitleSecond}>Technologies</span>
              </span>
              <span className={classes.mainSubTitle}>
                Flutter, React Native, etc.
              </span>
            </div>
            <div className={classes.spaceCircles}>
              <div className={classes.usedTechnologyWrapperDown2}>
                {MobileTechnologiesObject &&
                  MobileTechnologiesObject.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classes.usedTechnologyCircles}
                      >
                        <div className={classes.usedTechnologyinsideCircle}>
                          <img
                            src={item.Icon}
                            alt="usedTechnology Icons"
                            className={classes.usedTechnologyIcons}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.allUsedtechnology}>
        <div className={classes.titlesBesideOthers1}>
          <div className={classes.titlesContainerSecond}>
            <div className={classes.titlesContainer}>
              <span
                className={`${classes.mainTitle} ${classes.underLineCybersecurity}`}
              >
                Cybersecurity
                <span className={classes.mainTitleSecond}>Tools</span>
              </span>
              <span className={classes.mainSubTitle}>Kali, etc.</span>
            </div>
            <div className={classes.spaceCircles}>
              <div className={classes.usedTechnologyWrapperDown2}>
                {CybersecurityObject &&
                  CybersecurityObject.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classes.usedTechnologyCircles}
                      >
                        <div className={classes.usedTechnologyinsideCircle}>
                          <img
                            src={item.Icon}
                            alt="usedTechnology Icons"
                            className={classes.usedTechnologyIcons}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className={classes.titlesContainerSecond}>
            <div className={classes.titlesContainer}>
              <span
                className={`${classes.mainTitle} ${classes.underLineIntegration}`}
              >
                API Development &
                <span className={classes.mainTitleSecond}>Integration</span>
              </span>
              <span className={classes.mainSubTitle}>
                REST, SOAP or Swagger.
              </span>
            </div>
            <div className={classes.spaceCircles}>
              <div className={classes.usedTechnologyWrapper2}>
                {APIDevelopmentObject &&
                  APIDevelopmentObject.map((item, index) => {
                    const isSoapLogo = item.Icon === Soaplogo;

                    return (
                      <div
                        key={index}
                        className={classes.usedTechnologyCircles}
                      >
                        <div
                          className={`${classes.usedTechnologyinsideCircle} ${
                            isSoapLogo ? classes.blueground : ""
                          }`}
                        >
                          <img
                            src={item.Icon}
                            alt="usedTechnology Icons"
                            className={classes.usedTechnologyIcons}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.allUsedtechnology}>
        <div className={classes.titlesContainer}>
          <span
            className={`${classes.mainTitle} ${classes.underLineQualityAssurance}`}
          >
            Quality Assurance &
            <span className={classes.mainTitleSecond}>Testing Tools</span>
          </span>
          <span className={classes.mainSubTitle}>
            Postman, Jmeter, Selenium, Swagger,
            <span className={classes.mainSecondSubTitle}>
              Easy Redmine, etc.
            </span>
          </span>
        </div>
        <div className={classes.usedTechnologyWrapper}>
          {QualityAssurancObject &&
            QualityAssurancObject.map((item, index) => {
              return (
                <div key={index} className={classes.usedTechnologyCircles}>
                  <div className={classes.usedTechnologyinsideCircle}>
                    <img
                      src={item.Icon}
                      alt="usedTechnology Icons"
                      className={classes.usedTechnologyIcons}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className={classes.usedTechnologyWrapperMobile}>
          {QualityAssurancObjectMobile1 &&
            QualityAssurancObjectMobile1.map((item, index) => {
              return (
                <div key={index} className={classes.usedTechnologyCircles}>
                  <div className={classes.usedTechnologyinsideCircle}>
                    <img
                      src={item.Icon}
                      alt="usedTechnology Icons"
                      className={classes.usedTechnologyIcons}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div className={classes.usedTechnologyWrapperDownLast2}>
          {QualityAssurancObjectMobile2 &&
            QualityAssurancObjectMobile2.map((item, index) => {
              return (
                <div key={index} className={classes.usedTechnologyCircles}>
                  <div className={classes.usedTechnologyinsideCircle}>
                    <img
                      src={item.Icon}
                      alt="usedTechnology Icons"
                      className={classes.usedTechnologyIcons}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default UsedTechnology;
