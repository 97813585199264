import React from "react";
import LoanManagment from "./LoanManagment";
import DigitalWallet from "./DigitalWallet";
import classes from "./MoobiVault.module.scss";
import Header from "../../components/Header/Header";
import BusinessContainer from "./BusinessContainer";
import Footer from "../../components/Footer/Footer";
import PromoBanner from "../../components/PromoBanner/PromoBanner";
import MoobiBanners from "../../components/MoobiBanners/MoobiBanners";
import MobiVaultLogo from "../../assets/MoobiVaultImages/MoobiVault_Logo_icon.png";
import MobiVaultName from "../../assets/MoobiVaultImages/MoobiVaultName_Logo.svg";

function MoobiVault() {
  return (
    <>
      <Header />
      <MoobiBanners logoName={MobiVaultName} LogoIcon={MobiVaultLogo} style="MobiVault" />
      <div className="container">
      <div className={classes.titleMoobiVault}>
        <span className={classes.firstTitle}>
          Over<span className={classes.secondTitle}>view</span>
        </span>
        <p className={classes.paragraphStyle}>
          Moobitek is a Fintech Powerhouse. Our cutting-edge platforms deliver
          innovative financing and lending solutions, tailored to keep financial
          institutions and businesses ahead of the curve. Experience the future
          of fintech with Moobitek.
        </p>
      </div>
      </div>

      <LoanManagment />
      <div className={classes.businessContainerTitles}>
        <span className={classes.businessTitle}>
          Buy Now<span className={classes.businessTitleSecond}> Pay Later</span>
        </span>
        <p className={classes.businessParagraph}>
          Enhance your business with our Buy Now, Pay Later technology that
          service ecommerce and in store financing. Offer flexible payment
          options, boost sales, and elevate the customer experience with
          real-time approvals and secure, automated payment plans.
        </p>
      </div>
      <BusinessContainer />
      <DigitalWallet />
      <PromoBanner />
      <Footer />
    </>
  );
}

export default MoobiVault;
