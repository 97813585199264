import axios from "axios";
import React, { useState } from "react";
import Loader from "../../components/Loader/Loader";
import classes from "./ContactUs.module.scss";

function ProductTeam() {
  const [message, setMessage] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [workEmail, setWorkemail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [formMessage, setFormMessage] = useState({ message: "", type: "" });
  const { REACT_APP_API_ENDPOINT } = process.env;
  const type = "Quotation";

  const submit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (
      firstName.trim() === "" &&
      lastName.trim() === "" &&
      workEmail.trim() === "" &&
      phoneNumber.trim() === "" &&
      companyName.trim() === "" &&
      message.trim() === ""
    ) {
      setFormMessage({
        message: "All fields must be filled and valid.",
        type: "error",
      });
      setIsLoading(false);
      return;
    }
    axios({
      method: "POST",
      url: REACT_APP_API_ENDPOINT,
      data: {
        messageHtml: `<div><p>FirstName:${firstName}</p><p>LastName:${lastName}</p><p>WorkEmail:${workEmail}</p><p>PhoneNumber:${phoneNumber}</p><p>CompanyName:${companyName}</p><p>Message:${message}</p></div>`,
        type,
      },
    })
      .then((response) => {
        setIsLoading(false);
        if (response.data.msg === "success") {
          setFormMessage({ message: "Email sent!", type: "success" });
          resetForm();
        } else if (response.data.msg === "fail") {
          setFormMessage({
            message: "Oops, something went wrong. Try again",
            type: "error",
          });
        }
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        setFormMessage({
          message: "Oops, something went wrong. Please try again later.",
          type: "error",
        });
        setIsLoading(false);
      });
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setWorkemail("");
    setPhoneNumber("");
    setCompanyName("");
    setMessage("");
  };

  return (
    <form className={classes.ProductTeamContainer} onSubmit={submit}>
      <span className={classes.title}>
        Meet your
        <span className={classes.subTitle}>dedicated</span>
        product team.
      </span>
      <p className={classes.discription}>
        Please let us know how to contact you. We’ll reach out shortly with
        ideas about how we can bring your project to life.
      </p>
      <div className={classes.inputsPosition}>
        <div className={classes.titleInputStyle}>
          <span className={classes.TitelDesign}>First name</span>
          <input
            className={classes.inputFields}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
          />
        </div>
        <div className={classes.titleInputStyle}>
          <span className={classes.TitelDesign}>Last name</span>
          <input
            className={classes.inputFields}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
          />
        </div>
      </div>
      <div className={classes.inputsPosition}>
        <div className={classes.titleInputStyle} >
          <span className={classes.TitelDesign}>Work Email</span>
          <input
            className={classes.inputFields}
            value={workEmail}
            onChange={(e) => setWorkemail(e.target.value)}
            type="email"
          />
        </div>
        <div className={classes.titleInputStyle}>
          <span className={classes.TitelDesign}>Phone number</span>
          <input
            className={classes.inputFields}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            type="tel"
          />
        </div>
      </div>
      <div className={classes.inputsPosition}>
        <div className={classes.titleInputStyle}>
          <span className={classes.TitelDesign}>Company name</span>
          <input
            className={classes.inputFields}
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            type="text"
          />
        </div>
      </div>
      <div className={classes.inputsPosition}>
        <div className={classes.titleInputStyle}>
          <span className={classes.TitelDesign}>Quick Project Overview</span>
          <textarea
            className={classes.inputFieldsArea}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            type="text"
          />
        </div>
      </div>
      <button type="submit" className={classes.btnStyle} disabled={isLoading}>
        Submit
      </button>
      {isLoading && <Loader />}
      <div className={classes.messagePosition}>
        {formMessage.message && (
          <div className={classes.message}>{formMessage.message}</div>
        )}
      </div>
    </form>
  );
}

export default ProductTeam;
